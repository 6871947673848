import { Link, useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react'
import { useBreadcrumb } from '@features/vote/BreadcrumbContext'
import { useCustomTranslation } from '@features/translate/Translate'

const Breadcrumbs = () => {
  const { tr } = useCustomTranslation()
  const breadcrumbContext = useBreadcrumb()
  const location = useLocation()
  const breadcrumbs = breadcrumbContext.breadcrumbs.map((breadcrumb, index) => {
    if (breadcrumb.name === null) {
      breadcrumb.name = tr('Голосования')
    }
    return (
      <BreadcrumbItem key={index}>
        {index === 0 ? (
          <BreadcrumbLink as={Link} to="/votings" state={breadcrumb?.name}>
            {tr('Голосования')}
          </BreadcrumbLink>
        ) : (
          <BreadcrumbLink as={Link} to={breadcrumb.link} state={breadcrumb.name}>
            {breadcrumb.name}
          </BreadcrumbLink>
        )}
      </BreadcrumbItem>
    )
  })

  useEffect(() => {
    return breadcrumbContext.addBreadcrumb(location.pathname, location.state)
  }, [location.pathname])

  useEffect(
    () => breadcrumbContext.deleteBreadcrumbAndChildren(location.pathname),
    [location.pathname]
  )

  return (
    <Flex backgroundColor={'white'} borderRadius={'md'} py={'1.5'} px={'5'} mb={'5'}>
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
        {breadcrumbs}
      </Breadcrumb>
    </Flex>
  )
}
export default Breadcrumbs
