import { apiSlice } from '@app/apiSlice'
import {
  IVote,
  IVoteConfirm,
  IVotingCandidates,
  IVotingCategories,
  IVotingConcourses,
  UpdateConcourse,
} from '@features/vote/types'
// import { IUser } from './types'

export const voteApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Voting', 'Category', 'Nominant'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      vote: builder.mutation<void, IVote>({
        query: (data) => ({
          url: '/voting',
          method: 'POST',
          body: data,
        }),
      }),
      confirmVote: builder.mutation<void, IVoteConfirm>({
        query: (credentials) => ({
          url: '/voting/confirm',
          method: 'POST',
          body: credentials,
        }),
      }),
      getVotingConcourses: builder.query<
        IVotingConcourses[],
        IVotingConcourses['event_id']
      >({
        query: (eventId) => `/voting-management/events/${eventId}/concourses`,
        providesTags: ['Voting'],
      }),
      addVotingConcourses: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/concourse`,
            method: 'POST',
            body: data,
          }
        },
        invalidatesTags: ['Voting'],
      }),
      updateVotingConcourse: builder.mutation<void, UpdateConcourse>({
        query: (data) => {
          return {
            url: `/voting-management/concourse/${data.id}`,
            method: 'PUT',
            body: data,
          }
        },
        invalidatesTags: ['Voting'],
      }),
      deleteVotingConcourse: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/concourse/${data.id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['Voting'],
      }),
      getVotingCategories: builder.query<IVotingCategories[], IVotingCategories['id']>({
        query: (concourseId) => `/voting-management/concourse/${concourseId}/categories`,
        providesTags: ['Category'],
      }),
      addVotingCategory: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/category`,
            method: 'POST',
            body: data,
          }
        },
        invalidatesTags: ['Category'],
      }),
      updateVotingCategory: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/category/${data.id}`,
            method: 'PUT',
            body: data,
          }
        },
        invalidatesTags: ['Category'],
      }),
      deleteVotingCategory: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/category/${data.id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['Category'],
      }),
      getVotingCandidates: builder.query<IVotingCandidates[], any>({
        query: (data) =>
          `/voting-management/concourse/${data.concourseId}/category/${data.nominationId}/candidates`,
        providesTags: ['Nominant'],
      }),
      addVotingCandidate: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/candidate`,
            method: 'POST',
            body: data,
          }
        },
        invalidatesTags: ['Nominant'],
      }),
      updateVotingCandidate: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/candidate/${data.id}`,
            method: 'PUT',
            body: data,
          }
        },
        invalidatesTags: ['Nominant'],
      }),
      deleteVotingCandidate: builder.mutation({
        query: (data) => {
          return {
            url: `/voting-management/candidate/${data.id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['Nominant'],
      }),
    }),
  })

export const {
  useVoteMutation,
  useConfirmVoteMutation,
  useGetVotingConcoursesQuery,
  useGetVotingCategoriesQuery,
  useGetVotingCandidatesQuery,
  useAddVotingConcoursesMutation,
  useUpdateVotingConcourseMutation,
  useDeleteVotingConcourseMutation,
  useAddVotingCategoryMutation,
  useUpdateVotingCategoryMutation,
  useDeleteVotingCategoryMutation,
  useAddVotingCandidateMutation,
  useUpdateVotingCandidateMutation,
  useDeleteVotingCandidateMutation,
} = voteApiSlice
