import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { apiSlice } from '@app/apiSlice'
import { RootState } from '@app/store'
import { eventsActions } from '@features/events/eventsSlice' //TODO: не менять, иначе будет кольцевой импорт
import { IUser } from './types'
import { profileActions } from '@features/profile'

interface IInitialState {
  email: IUser['email'] | null
  token: IUser['token'] | null
}
const initialState: IInitialState = { email: null, token: null }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<IInitialState>) => {
      const { email, token } = action.payload
      state.email = email
      state.token = token
    },
    reset: () => initialState,
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
  },
})

export const logOut = createAsyncThunk('auth/logout', async (arg, thunkAPI) => {
  //TODO: добавить удаление кук: https://stackoverflow.com/questions/68929107/how-to-purge-any-persisted-state-using-react-tool-kit-with-redux-persist
  thunkAPI.dispatch(eventsActions.reset())
  thunkAPI.dispatch(authSlice.actions.reset())
  thunkAPI.dispatch(apiSlice.util.resetApiState())
  thunkAPI.dispatch(profileActions.resetProfile())
})

export const authActions = { ...authSlice.actions, logOut }
export const authReducer = authSlice.reducer

export const selectCurrentEmail = (state: RootState) => state.auth.email
export const selectCurrentToken = (state: RootState) => state.auth.token
export const { setEmail } = authSlice.actions
