import { FC, ReactNode } from 'react'
import { ErrorBoundary as EB } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'

type ErrorBoundaryProps = {
  children: ReactNode
}
const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => (
  <EB FallbackComponent={ErrorFallback}>{children}</EB>
)

export default ErrorBoundary
