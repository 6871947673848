import { object, string, number } from 'yup'
// import { userApi } from 'app/api'
// import { store } from 'app/store'

export const schema = () => ({
  1: object({
    email: string().email('Неверный формат email').required('Поле не заполнено'),
    // .test(async (value, { createError }) => {
    // const result = await store.dispatch(userApi.endpoints.getUsers.initiate({}))
    // else return createError({ message: 'Такой email уже зарегистрирован' })
    // }),
  }).required(),
  2: object({
    code: string().required('Поле не заполнено'),
  }).required(),
})
