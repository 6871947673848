import React, { FC, ReactNode } from 'react'
import { useAppSelector } from '@app/store'
import { selectCurrentEventId } from './eventsSlice'
import { Box, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

const CurrEventChecker: FC<{ children: ReactNode }> = ({ children }) => {
  const currentEventId = useAppSelector(selectCurrentEventId)
  const location = useLocation()

  if (!currentEventId && location.pathname != '/')
    return (
      <Box>
        <Text>Сначала выберите выставку</Text>
      </Box>
    )
  return <>{children}</>
}

export default CurrEventChecker
