import React, { FC } from 'react'
import { Image, ImageProps } from '@chakra-ui/react'
import { Modify } from '@utils/customTs'

type EventLogoProps = Modify<
  ImageProps,
  {
    src?: string | null
  }
>

const EventLogo: FC<EventLogoProps> = (props) => {
  const getSrc = (src?: string | null) => {
    //возвращает путь к лого от корняы папки public
    if (src === null) return './defaultEventLogo.svg'
    else if (src === undefined) return './whiteBackground.svg'
    else if (src) return src
  }
  return (
    <Image
      {...props}
      src={getSrc(props.src)}
      fallbackSrc={'./defaultEventLogo.svg'}
      loading={'lazy'}
      alt={props.alt ?? 'ITE Event'}
      borderRadius="sm"
      maxH={'100%'}
      objectFit={'contain'}
    />
  )
}

export default EventLogo
