import React, { FC, ReactNode } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Text,
} from '@chakra-ui/react'
import Spinner from '@components/Spinner'
import ErrorFallback from './ErrorBoundary/ErrorFallback'

type LoaderProps = {
  isLoading: boolean
  children: ReactNode
  data: any
  emptyDataText?: string
}
const Loader: FC<LoaderProps> = (props) => {
  //чтобы текст был по центру, в родителе используй <Flex minH={'NNNpx'} flexDirection={'column'}>
  // if (1) return <Spinner />
  if (props.isLoading) return <Spinner />
  else if (!props.data)
    return (
      <Center flex={'1'}>
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>В процессе загрузки данных произошла ошибка</AlertDescription>
        </Alert>
      </Center>
    )
  else if (props.emptyDataText && props.data.length == 0)
    return (
      <Center flex={'1'}>
        <Text fontSize={'lg'}>{props.emptyDataText}</Text>
      </Center>
    )
  else return <>{props.children}</>
}

export default Loader
