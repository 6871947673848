import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authActions } from '@features/auth/authSlice' //FIXME: circular import
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithAutoLogout = async (
  args: any,
  api: any,
  extraOptions: any
): Promise<any> => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    // если токен истек, то логаут
    api.dispatch(authActions.logOut())
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithAutoLogout,
  endpoints: (builder) => ({}),
})

const pureQuery = fetchBaseQuery({
  baseUrl: '',
  credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const fetchApiSlice = createApi({
  baseQuery: pureQuery,
  endpoints: (builder) => ({
    fetch: builder.query<any, any>({
      query: (src) => src,
    }),
  }),
})

export const { useFetchQuery, useLazyFetchQuery } = fetchApiSlice
