import React, { FC, memo, ReactNode, useMemo } from 'react'
import {
  Flex,
  Heading,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useLocation, useParams } from 'react-router-dom'
import { PlusSquareIcon } from '@chakra-ui/icons'
import { useCustomTranslation } from '@features/translate/Translate'
import { useGetVotingCategoriesQuery } from '@features/vote/voteApiSlice'
import CompetitionTableRequest from '@features/vote/CompetitionTable/CompetitionTableRequest'
import ModalSimple from '@components/ModalSimple'
import CompetitionEditForm from '@features/vote/CompetitionTable/CompetitionEditForm'

const ThText: FC<{ children: ReactNode }> = ({ children }) => (
  <Text mb={'3'} fontSize={'xs'}>
    {children}
  </Text>
)

const CompetitionTable: FC = () => {
  const { concourseId = '' } = useParams()
  const { data = [] } = useGetVotingCategoriesQuery(+concourseId)
  const { isOpen: isOpenCEM, onOpen: onOpenCEM, onClose: onCloseCEM } = useDisclosure()
  const location = useLocation()
  const concourseTitle = location.state
  const { tr } = useCustomTranslation()
  // console.log(data)

  const sortedByCategoryId = useMemo(
    () => (data ? [...data].sort((a, b) => a.id - b.id) : []),
    [data]
  )

  return (
    <>
      <Flex
        backgroundColor={'white'}
        borderRadius={'md'}
        p={'5'}
        minH={'100px'}
        flexDirection={'column'}
      >
        <Heading as="h1" size="md" mb={'5'}>
          {concourseTitle}
        </Heading>
        <Flex justifyContent="right" mb="2">
          <Tooltip hasArrow label={tr('Добавить номинацию')} bg="gray.300" color="black">
            <IconButton
              icon={<PlusSquareIcon />}
              aria-label="Add a nomination"
              variant="outline"
              onClick={onOpenCEM}
            />
          </Tooltip>
          <ModalSimple size={'lg'} isOpen={isOpenCEM} onClose={onCloseCEM}>
            <CompetitionEditForm onClose={onCloseCEM} />
          </ModalSimple>
        </Flex>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th width={'100%'}>
                  <ThText>{tr('Номинация')}</ThText>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedByCategoryId.map((categoryRequest) => {
                return (
                  <CompetitionTableRequest
                    key={categoryRequest.id}
                    categoryData={categoryRequest}
                  />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </>
  )
}

export default memo(CompetitionTable)
