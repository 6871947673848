import { apiSlice } from '@app/apiSlice'
import { ConfirmResponse, IUser } from './types'

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<void, IUser['email']>({
      query: (email) => ({
        url: '/auth/login',
        method: 'POST',
        body: { email },
      }),
    }),
    confirm: builder.mutation<ConfirmResponse, { email: IUser['email']; code: string }>({
      query: (credentials) => ({
        url: '/auth/confirm',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
})

export const { useLoginMutation, useConfirmMutation } = authApiSlice
