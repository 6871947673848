import { useEffect, useState } from 'react'

const isEqual = (a: any, b: any) => {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b
  if (a.constructor !== b.constructor) return false

  for (const key in a) {
    if (!a.hasOwnProperty(key)) continue
    if (!b.hasOwnProperty(key)) return false
    if (!isEqual(a[key], b[key])) return false
  }

  for (const key in b) {
    if (b.hasOwnProperty(key) && !a.hasOwnProperty(key)) return false
  }

  return true
}

export const useMemoizedNestedObject = (value: any) => {
  //мемоизирует сложные вложенные объекты, если значение не меняется, то возвращается тот же объект
  const [storedValue, setStoredValue] = useState(value)

  useEffect(() => {
    if (!isEqual(value, storedValue)) {
      setStoredValue(value)
    }
  }, [value, isEqual, storedValue])

  return storedValue
}
