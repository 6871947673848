import { Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useCustomTranslation } from '@features/translate/Translate'
import { Outlet, useNavigate } from 'react-router-dom'
import Breadcrumbs from '@features/vote/Breadcrumbs'
import { BreadcrumbProvider } from '@features/vote/BreadcrumbContext'
import { useAppSelector } from '@app/store'
import { selectCurrentEventId } from '@features/events'

const VotingAdminPage = () => {
  const { tr } = useCustomTranslation()
  const eventId = useAppSelector(selectCurrentEventId)
  const [currentEvent, setCurrentEvent] = useState(eventId)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentEvent !== eventId) {
      setCurrentEvent(eventId)
      navigate('/votings')
    }
  }, [currentEvent, eventId, navigate])

  return (
    <>
      <Heading as="h1" size="md" mb={'5'}>
        {tr('Голосования')}
      </Heading>
      <BreadcrumbProvider>
        <Breadcrumbs />
        <Outlet />
      </BreadcrumbProvider>
    </>
  )
}

export default VotingAdminPage
