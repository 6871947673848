import React, { FC } from 'react'
import { InputWrapperProps, InputWrapper } from '@components/Form'
import FileName from '../../../FileName'
import { useFormContext } from 'react-hook-form'

type FileFieldProps = InputWrapperProps

const FileField: FC<FileFieldProps> = (props) => {
  const { watch } = useFormContext()
  const file = watch(props.inputName)
  return (
    <>
      {file && file[0]?.name && (
        <InputWrapper {...props}>
          <FileName fileName={file[0]?.name}></FileName>
        </InputWrapper>
      )}
    </>
  )
}

export default FileField
