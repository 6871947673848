import React, { FC } from 'react'
import { BoxProps, Flex, Icon, Text } from '@chakra-ui/react'
import { FaFileAlt } from 'react-icons/fa'

type FileNameProps = BoxProps & {
  fileName: string
}

const FileName: FC<FileNameProps> = ({ fileName, ...rest }) => {
  return (
    <Flex gap={'2'} alignItems={'center'} {...rest}>
      <Icon as={FaFileAlt} boxSize={'2em'} />
      <Text fontSize={['md', 'lg']} noOfLines={1}>
        {fileName}
      </Text>
    </Flex>
  )
}

export default FileName
