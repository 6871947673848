import React, { useEffect, useState } from 'react'
import { Text } from '@chakra-ui/react'

type CountdownProps = {
  initialTime: number
}

const Countdown: React.FC<CountdownProps> = ({ initialTime }) => {
  const [seconds, setSeconds] = useState(initialTime)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds - 1)
    }, 1000)
    if (seconds <= 0) window.location.reload()
    return () => clearInterval(interval)
  }, [seconds])

  const minutes = Math.floor(seconds / 60)
  const sec = (seconds % 60).toString().padStart(2, '0')

  return (
    <Text>
      Осталось времени: {minutes}:{sec}
    </Text>
  )
}

export default Countdown
