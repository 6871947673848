import React, { FC, memo } from 'react'
import {
  ButtonGroup,
  IconButton,
  Td,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { IVotingCategories } from '@features/vote/types'
import ModalSimple from '@components/ModalSimple'
import CompetitionEditForm from '@features/vote/CompetitionTable/CompetitionEditForm'
import { useDeleteVotingCategoryMutation } from '@features/vote/voteApiSlice'
import useConfirmDialog from '@hooks/useConfirmDialog'
import { useCustomTranslation } from '@features/translate/Translate'

type CategoryRequestProps = {
  categoryData: IVotingCategories
}

const CompetitionTableRequest: FC<CategoryRequestProps> = ({ categoryData }) => {
  const { tr } = useCustomTranslation()
  const [deleteVotingCategory, { isLoading }] = useDeleteVotingCategoryMutation()
  const { isOpen: isOpenCEM, onOpen: onOpenCEM, onClose: onCloseCEM } = useDisclosure()
  const {
    openConfirmDialog: openConfirmDeleteDialog,
    ConfirmDialog: ConfirmDeleteDialog,
  } = useConfirmDialog()
  const toast = useToast()
  const handleRemoveConcourse = () => {
    deleteVotingCategory({ id: categoryData.id })
      .unwrap()
      .then(() => {
        toast({
          description: tr('Номинация удалена'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          description: 'При удалении номинации произошла ошибка',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }
  return (
    <Tr>
      <Td>
        <Link to={`${categoryData.id}`} state={categoryData.name}>
          {categoryData.name}
        </Link>
      </Td>
      <Td textAlign={'right'}>
        <ButtonGroup size={'sm'}>
          <IconButton aria-label="Edit" icon={<EditIcon />} onClick={onOpenCEM} />
          <IconButton
            aria-label="Delete"
            icon={<DeleteIcon />}
            onClick={openConfirmDeleteDialog(() => handleRemoveConcourse())}
          />
          <ConfirmDeleteDialog
            title={tr('Удаление данных')}
            text={tr('Вы действительно хотите удалить эту номинацию?')}
          />
        </ButtonGroup>
        <ModalSimple size={'lg'} isOpen={isOpenCEM} onClose={onCloseCEM}>
          <CompetitionEditForm categoryData={categoryData} onClose={onCloseCEM} />
        </ModalSimple>
      </Td>
    </Tr>
  )
}

export default memo(CompetitionTableRequest)
