import { mixed, object, string } from 'yup'
import { isEmpty } from '@utils/isEmpty'

export const schema = object({
  name: string()
    .required('Название выставки является обязательным')
    .min(2, 'В названии выставки должно быть минимум 2 символа')
    .matches(
      /^[а-яА-Яa-zA-Z\d &]+$/,
      'Название выставки может содержать только русские или английские символы, пробелы и числа'
    ),
  start: string()
    .required('Поле является обязательным')
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      'Дата начала выставки должно быть в формате ГГГГ-ММ-ДД'
    )
    .matches(/^[-\d]+$/, 'Дата может содержать только цифры и символ -'),
  finish: string()
    .required('Поле является обязательным')
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      'Дата окончания выставки  должно быть в формате ГГГГ-ММ-ДД'
    )
    .matches(/^[-\d]+$/, 'Дата может содержать только цифры и символ -')
    .test(
      'start-before-finish',
      'Дата окончания выставки должна быть такой же или позже даты начала',
      function (value) {
        return new Date(this.parent.start) <= new Date(value || '')
      }
    ),
  logo: mixed().test({
    message: 'Неверный формат файла',
    test: (file, context) => {
      const correctFileType =
        file[0]?.type == 'image/png' ||
        file[0]?.type == 'image/svg+xml' ||
        file[0]?.type == 'image/jpg' ||
        file[0]?.type == 'image/jpeg'
      const defaultLogo = file.length === 0
      const isValid = isEmpty(file[0]) || correctFileType || defaultLogo
      if (!isValid) context?.createError()
      return isValid
    },
  }),
}).required()
