import React, { ReactNode } from 'react'
import { Box, Divider, Flex, VStack } from '@chakra-ui/react'
import SideBar from './SideBar'
import { Outlet } from 'react-router-dom'
import Sidebar from './SideBar'

interface ProfileLayoutProps {
  children?: React.ReactNode
}

const ProfileLayout: React.FC<ProfileLayoutProps> = ({ children }) => {
  return (
    <Flex direction="column" width="100%" bg="gray.100">
      <Flex justify="center">
        <Box bg="white" p={3} borderWidth="1px" borderRadius="md" width="100%">
          <Sidebar />
        </Box>
      </Flex>
      <Flex flex="1" overflowY="auto" py={3} direction="column">
        <Box width="100%">{children || <Outlet />}</Box>
      </Flex>
    </Flex>
  )
}

export default ProfileLayout
