import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Flex, Text } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'

const StepFinal = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const status = searchParams.get('status')
  setTimeout(() => navigate(-1), 4000)
  return (
    <Flex align={'center'} gap={'3'}>
      <CheckCircleIcon color="teal" boxSize={'1.5em'} />
      <Text fontSize={'xl'}>
        Ваш голос засчитан, сейчас вы будете перенаправлены обратно на страницу с номинантом.
      </Text>
    </Flex>
  )
}

export default StepFinal
