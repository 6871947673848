import { Box, Code, Heading, IconButton, useClipboard, useToast } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useAppSelector } from '@app/store'
import { selectCurrentEventId } from '@features/events'
import { useParams } from 'react-router-dom'
import { IVotingCandidates } from '@features/vote/types'
import { FaCopy } from 'react-icons/fa'
import { useCustomTranslation } from '@features/translate/Translate'

type CandidateSourceModalProps = {
  onClose: () => void
  candidateData?: IVotingCandidates
}

const CandidateSourceModal: FC<CandidateSourceModalProps> = (props) => {
  const { tr } = useCustomTranslation()
  const eventId = useAppSelector(selectCurrentEventId)
  const { concourseId = '' } = useParams()
  const { nominationId = '' } = useParams()
  const candidateId = props.candidateData?.id
  console.log(props.candidateData?.id)

  const CopyToClipboard = () => {
    const { onCopy } = useClipboard(
      `<Vote eventId="${eventId}" concourse='${concourseId}' category='${nominationId}' candidate='${candidateId}'/>`
    )
    const toast = useToast()
    const onClick = () => {
      onCopy()
      toast({
        description: `${tr('Код')} ${props.candidateData?.name} ${tr('скопирован')}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }
    return (
      <Box pos="absolute" top={'0'} right={'0'}>
        <IconButton
          color={'gray.900'}
          aria-label="Copy to clipboard"
          icon={<FaCopy />}
          onClick={onClick}
        />
      </Box>
    )
  }

  return (
    <>
      <Heading size={'md'} mb={'5'}>
        {tr('Код для вставки номинанта')} "{props.candidateData?.name}"
      </Heading>

      <Box pos="relative" ml={'auto'}>
        <Code
          p={'2.5'}
          children={`<Vote eventId='${eventId}' concourse='${concourseId}' category='${nominationId}' candidate='${candidateId}'/>`}
        />
        <CopyToClipboard />
      </Box>
    </>
  )
}

export default CandidateSourceModal
