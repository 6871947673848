import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, store } from '@app/store'
import App from '@app/App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import '@features/translate/i18n'
import { PersistGate } from 'redux-persist/integration/react'
import theme from '@app/theme'
import { AbilityContextProvider } from '@features/roles'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <AbilityContextProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ChakraProvider theme={theme}>
              <App />
            </ChakraProvider>
          </Router>
        </PersistGate>
      </Provider>
    </AbilityContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
