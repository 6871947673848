import React from 'react'
import { Box, VStack, LinkBox, LinkOverlay, HStack, Text } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useCustomTranslation } from '@features/translate/Translate'

const Sidebar = () => {
  const { tr } = useCustomTranslation()

  const location = useLocation()

  return (
    <Box>
      <HStack spacing={4} align="center" justify="center">
        <LinkBox
          as="div"
          py={2}
          color={location.pathname === '/profile' ? 'white' : 'gray'}
          bg={location.pathname === '/profile' ? 'gray.800' : 'white'}
          borderRadius="md"
          w={'20%'}
        >
          <LinkOverlay as={Link} to="/profile">
            <Text textAlign="center">{tr('Главная')}</Text>
          </LinkOverlay>
        </LinkBox>
        <LinkBox
          as="div"
          py={2}
          color={location.pathname === '/profile/personal-info' ? 'white' : 'gray'}
          bg={location.pathname === '/profile/personal-info' ? 'gray.800' : 'white'}
          borderRadius="md"
          transition="1s ease"
          w={'20%'}
        >
          <LinkOverlay as={Link} to="/profile/personal-info">
            <Text textAlign="center">{tr('Личная информация')}</Text>
          </LinkOverlay>
        </LinkBox>
        <LinkBox
          as="div"
          py={2}
          color={location.pathname === '/profile/security' ? 'white' : 'gray'}
          bg={location.pathname === '/profile/security' ? 'gray.800' : 'white'}
          borderRadius="md"
          transition="1s ease"
          w={'20%'}
        >
          <LinkOverlay as={Link} to="/profile/security">
            <Text textAlign="center">{tr('Безопасность')}</Text>
          </LinkOverlay>
        </LinkBox>
      </HStack>
    </Box>
  )
}
export default Sidebar
