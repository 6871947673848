import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { DEFAULT_LANGUAGE } from '@app/consts'
import en from '@locales/en/translation.json'
import ru from '@locales/ru/translation.json'
import { convertLanguageJsonToObject } from './translations'

export const translationsJson = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
}

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en)

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translationsJson,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  })
