import { AbilityContext } from './Can'
import { useAbility } from '@casl/react'
import { IPermission } from './types'
import { useState } from 'react'
import { AnyAbility } from '@casl/ability'

type UseCustomAbilityReturn = {
  ability: AnyAbility
  updateAbility: (permissionArray: IPermission[]) => void
  wasUpdated: boolean
}

export const useCustomAbility = () => {
  const ability = useAbility(AbilityContext)
  const [wasUpdated, setWasUpdated] = useState<boolean>(false)
  const updateAbility: UseCustomAbilityReturn['updateAbility'] = (permissionArray) => {
    ability.update(permissionArray)
    setWasUpdated((prev) => !prev)
  }
  return {
    ability,
    updateAbility,
    wasUpdated,
  }
}
