import React, { useCallback, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, AlertDialogProps } from '@components/AlertDialog'

type submitHandler = AlertDialogProps['onSubmit']

const useConfirmDialog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [onSubmit, setOnSubmit] = useState<submitHandler>()

  const ConfirmDialog = useMemo(
    () =>
      ({ title, text }: { title: string; text: string }) => {
        return (
          <>
            {onSubmit && (
              <AlertDialog
                onClose={onClose}
                open={isOpen}
                onSubmit={onSubmit}
                title={title}
                text={text}
              />
            )}
          </>
        )
      },
    [onSubmit, isOpen]
  )

  const openConfirmDialog = useCallback(
    (onSubmit: submitHandler) => {
      return () => {
        onOpen()
        setOnSubmit(() => onSubmit)
      }
    },
    [onSubmit]
  )

  return {
    openConfirmDialog, //открывает диалоговое окно, принимает submit обработчик
    ConfirmDialog, //компонент CompetitionsEditForm диалоговым окном
  }
}

export default useConfirmDialog
