import React from 'react'
import { Box, Center, Icon } from '@chakra-ui/react'
import { FaUserCircle } from 'react-icons/fa'
import { MultistepForm } from '@components/Form'
import { schema } from './validation'
const Step_1 = React.lazy(() => import('./Steps/EmailConfirm'))
const Step_final = React.lazy(() => import('./Steps/PinCodeConfirm'))
const LoginPage = () => {
  return (
    <>
      <Center mb={[5, 10]}>
        <Icon as={FaUserCircle} w={[100, 100]} h={[100, 100]} color={'gray.1000'} />
      </Center>
      <MultistepForm schema={schema} stepComponents={[Step_1, Step_final]} />
    </>
  )
}

export default LoginPage
