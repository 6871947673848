import React, { FC } from 'react'
import { Props, Select as ReactSelect } from 'chakra-react-select'
import { Controller, useFormContext } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import { InputProps } from '@chakra-ui/react'

type SelectProps = InputProps &
  Props & {
    inputName: string
    isRequired?: boolean
    labelText?: string
  }

const Select: FC<SelectProps> = (props) => {
  const { inputName, labelText, options, ...rest } = props

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <InputWrapper {...props}>
      <Controller
        control={control}
        name={inputName}
        render={({ field: { ...restField } }) => (
          <ReactSelect
            options={options}
            isInvalid={!!errors[inputName]}
            {...restField}
            {...rest}
          />
        )}
      />
    </InputWrapper>
  )
}

export default Select
