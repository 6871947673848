import { apiSlice } from '@app/apiSlice'
import { ProfileGet, ProfileUpdate } from '@features/users/types'

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<ProfileGet, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation<void, { id: number; data: ProfileUpdate }>({
      query: ({ id, data }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    changeEmail: builder.mutation<void, { data: { newEmail: string; email: string } }>({
      query: ({ data }) => ({
        url: `/users/changeEmail`,
        method: 'POST',
        body: data,
      }),
    }),
    confirmChangeEmail: builder.mutation<
      void,
      { data: { currentEmailCode: string; newEmailCode: string; newEmail: string } }
    >({
      query: ({ data }) => ({
        url: `/users/confirm-email-change`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const {
  useGetUserInfoQuery,
  useUpdateUserMutation,
  useChangeEmailMutation,
  useConfirmChangeEmailMutation,
} = profileApiSlice
