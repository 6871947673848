//TODO: при обновлении эвента сделать invalidatesTags на загрузку одного эвента, а не всех
import { createSelector } from '@reduxjs/toolkit'
import { apiSlice } from '@app/apiSlice'
import { objToFormData } from '@utils/objToFormData'
import { IEvent } from './types'
import { selectCurrentEventId } from './eventsSlice'
import { RootState } from '@app/store'

export const eventsApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Events'] }) //фикс для TS, аналог tagTypes: ['Events'],
  .injectEndpoints({
    endpoints: (builder) => ({
      addEvent: builder.mutation<void, IEvent>({
        query: (event) => {
          return {
            url: '/events',
            method: 'POST',
            body: objToFormData(event),
          }
        },
        invalidatesTags: ['Events'],
      }),
      updateEvent: builder.mutation<void, IEvent>({
        query: (event) => {
          return {
            url: `/events/${event.id}`,
            method: 'PUT',
            body: objToFormData(event),
          }
        },
        invalidatesTags: ['Events'],
      }),

      removeEvent: builder.mutation<void, IEvent['id']>({
        query: (eventId) => ({
          url: `/events/${eventId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Events'],
      }),
      getEvents: builder.query<IEvent[], void>({
        query: () => `/events`,
        providesTags: ['Events'],
      }),
      getEvent: builder.query<IEvent, IEvent['id']>({
        query: (eventId) => `/events/${eventId}`,
        providesTags: ['Events'],
      }),
      getEventLogo: builder.query<{url:IEvent['logo']}, IEvent['id']>({
        query: (eventId) => `/events/${eventId}/logo`,
      }),
    }),
  })

export const {
  useGetEventsQuery,
  useLazyGetEventQuery,
  useGetEventQuery,
  useGetEventLogoQuery,
  useLazyGetEventsQuery,
  useAddEventMutation,
  useUpdateEventMutation,
  useRemoveEventMutation,
} = eventsApiSlice

export const selectEventsData = () => eventsApiSlice.endpoints.getEvents.select()

export const selectEventData = (eventId: IEvent['id']) =>
  createSelector(
    selectEventsData(),
    (eventsData) => eventsData.data?.filter((event: IEvent) => event.id == eventId)[0]
  )

export const selectCurrentEventData = (state: any) =>
  createSelector(
    selectEventsData(),
    (eventsData) =>
      eventsData.data?.filter(
        (event: IEvent) => event.id == selectCurrentEventId(state)
      )[0]
  )(state)
