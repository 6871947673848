import {
  useState,
  createContext,
  FC,
  ReactNode,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'

type FieldValue = string

type MultistepFormContextProps = {
  savedFields: { [key: string]: FieldValue }
  saveFieldValues: Dispatch<SetStateAction<any>>
}

export const MultistepFormContext = createContext<MultistepFormContextProps>(
  {} as MultistepFormContextProps
)

export const useMultistepFormContext = () => {
  return useContext(MultistepFormContext)
}

export const MultistepFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [savedFields, setSavedFields] = useState<
    MultistepFormContextProps['savedFields']
  >({})
  const saveFieldValues = (newValues: MultistepFormContextProps['savedFields']) => {
    setSavedFields({
      ...savedFields,
      ...newValues,
    })
  }

  return (
    <MultistepFormContext.Provider value={{ savedFields, saveFieldValues }}>
      {children}
    </MultistepFormContext.Provider>
  )
}
