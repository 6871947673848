//TODO: в ErrorFallback добавить код ошибки
//TODO: разобраться с ошибкой FETCH_ERROR и error status
//TODO: обернуть компоненты в suspense и errorBoundary, убрать лишние return
//TODO: изменить поведение при отсутствии соединения с сервером
import React, { FC, memo } from 'react'
import { Flex, SimpleGrid } from '@chakra-ui/react'
import { useActions, useAppSelector } from '@app/store'
import { useGetEventsQuery } from '../eventsApiSlice'
import { IEvent } from '../types'
import { EventCardExtended, EventCardSimple } from '../EventCards'
import { selectCurrentEventId } from '../eventsSlice'
import Loader from '@components/Loader'

type EventsControlProps = {
  onClose: () => void
  editMode: boolean
  selectedYear: string
}

const EventsControl: FC<EventsControlProps> = (props) => {
  const { setCurrentEventId } = useActions()
  const { data, error, isLoading } = useGetEventsQuery()
  const currentEventId = useAppSelector(selectCurrentEventId)
  const filteredEvents = data?.filter(
    (event) => new Date(event.start).getFullYear().toString() === props.selectedYear
  )

  return (
    <>
      <Flex minH={'100px'} flexDirection={'column'}>
        <Loader
          isLoading={isLoading}
          data={filteredEvents}
          emptyDataText={'Создайте новый эвент или выберите существующий'}
        >
          <SimpleGrid
            spacing={3}
            justifyContent={'center'}
            templateColumns={{
              base: 'repeat(auto-fit, minmax(120px, 1fr))',
              sm: 'repeat(auto-fit, minmax(130px, 250px))',
            }}
          >
            {filteredEvents &&
              filteredEvents?.map((event: IEvent) =>
                props.editMode ? (
                  <EventCardExtended
                    key={event.id}
                    isActive={event.id === currentEventId}
                    eventData={event}
                  />
                ) : (
                  <EventCardSimple
                    key={event.id}
                    isActive={event.id === currentEventId}
                    eventData={event}
                    onClick={() => {
                      setCurrentEventId(event.id)
                      setTimeout(() => props.onClose(), 150)
                    }}
                  />
                )
              )}
          </SimpleGrid>
        </Loader>
      </Flex>
    </>
  )
}

export default memo(EventsControl)
