import { useTranslation } from 'react-i18next'

/* get - возвращает нужное свойство объекта obj имя которого указанао с помощью строки path
 * Пример:
 * const user = {name:"Ivan"}
 * get(user, "user.name", '') //Ivan
 * */
// const get = (obj: any, path: any, def: any) =>
//   (() => (typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.')))()
//     .split('.')
//     .filter(Boolean)
//     .every((step: any) => (obj = obj[step]) !== undefined)
//     ? obj
//     : def

type TranslateProps = {
  i18key: string
}

type TransProps = {
  children: React.ReactNode
}
export const useCustomTranslation = () => {
  const { t } = useTranslation()
  const tr = (arg:any) => {
    return t(arg)
  }
  return {tr}
}


export const Trans = ({ children }: TransProps) => {
  const { t } = useTranslation()
  return <>{t(children as string)}</>
}

export const Translate = ({ i18key }: TranslateProps) => {
  const { t } = useTranslation()
  return <>{t(i18key)}</>
}
