//TODO: добавить useMemo в useActions
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action, bindActionCreators, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { apiSlice } from './apiSlice'
import { authActions, authReducer } from '@features/auth'
import { eventsActions, eventsReducer } from '@features/events'

import { profileActions, profileReducer } from '@features/profile'

import storage from 'redux-persist/lib/storage'
// import sessionStorage from 'redux-persist/lib/storage/session'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  REHYDRATE,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { rtkQueryErrorLogger } from '@app/rtkQueryErrorLogger'

const persistAuthConfig = {
  key: 'auth',
  storage,
}
const persistEventsConfig = {
  key: 'events',
  storage,
}

const persistProfileConfig = {
  key: 'profile',
  storage,
}

const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer)
const persistedEventsReducer = persistReducer(persistEventsConfig, eventsReducer)
const persistedProfileReducer = persistReducer(persistProfileConfig, profileReducer)

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistedAuthReducer,
    events: persistedEventsReducer,
    profile: persistedProfileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    })
      .prepend(rtkQueryErrorLogger)
      .concat(apiSlice.middleware),
  devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useActions(
  actions = { ...authActions, ...eventsActions, ...profileActions },
  deps = []
) {
  const dispatch = useDispatch()
  // return useMemo(
  //   () => {
  //     if (Array.isArray(actions)) {
  //       return { ...actions.map((a) => bindActionCreators(a, dispatch)) }
  //     }
  //     return bindActionCreators(actions, dispatch)
  //   },
  //   deps ? [dispatch, ...deps] : [dispatch]
  // )
  return bindActionCreators(actions, dispatch)
}

export default store
export const persistor = persistStore(store)
