import { useNavigate } from 'react-router-dom'
import { Button, Stack, useToast } from '@chakra-ui/react'
import {
  Form,
  MultistepFormComponentProps,
  PinCodeInput,
  useCustomForm,
  useMultistepFormContext,
} from '@components/Form'
import Countdown from '@components/Countdown'
import { InferType } from 'yup'
import { useConfirmVoteMutation } from '../voteApiSlice'

export default function PinCodeConfirm(props: MultistepFormComponentProps) {
  const [confirmVote, { isLoading }] = useConfirmVoteMutation()
  const methods = useCustomForm({
    schema: props.schema,
    firstFieldFocus: true,
    resetOnSubmit: false,
  })
  const navigate = useNavigate()
  const { savedFields } = useMultistepFormContext()
  const toast = useToast()
  const onSubmit = (formFields: InferType<typeof props.schema>) => {
    return confirmVote({
      email: savedFields.email,
      code: formFields.code,
    })
      .unwrap()
      .then(() => {
        props.stepMethods.next()
      })
      .catch((e) => {
        // console.log(e)
        if (e.status == 400)
          methods.setError('code', {
            type: 'custom',
            message: 'Неправильный код',
          })
        else if (e.status == 429) {
          methods.setFormError()
          toast({
            description: 'Превышено количество запросов',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
        //   setTimeout(() => navigate(-1), 4000)
        else {
          methods.setFormError()
          toast({
            description: 'При отправке данных произошла ошибка',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      })
  }

  return (
    <Form onSubmit={onSubmit} {...methods}>
      <Stack direction={['column']} spacing="4">
        <PinCodeInput isRequired inputName={'code'} labelText={'Код подтверждения'} />
        <Countdown initialTime={179} />
      </Stack>
      <Stack direction={['column', 'row']} mt={8}>
        <Button type={'submit'} isLoading={isLoading}>
          Далее
        </Button>
      </Stack>
    </Form>
  )
}
