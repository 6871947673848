import { Input, InputProps } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import InputWrapper from './InputWrapper'

type FormInputProps = InputProps & {
  inputName: string
  isRequired?: boolean
  labelText?: string
}

const TextInput: FC<FormInputProps> = (props) => {
  const { inputName, placeholder, labelText, ...rest } = props
  const { register } = useFormContext()
  return (
    <InputWrapper {...props}>
      <Input
        id={inputName}
        placeholder={placeholder || ''}
        {...register(inputName)}
        {...rest}
      />
    </InputWrapper>
  )
}

export default TextInput
