import React, { FC } from 'react'
import { IVotingCategories } from '@features/vote/types'
import {
  useAddVotingCategoryMutation,
  useUpdateVotingCategoryMutation,
} from '@features/vote/voteApiSlice'
import { Button, Heading, Stack, useToast } from '@chakra-ui/react'
import { Form, TextInput, useCustomForm } from '@components/Form'
import { schema } from '@features/vote/CompetitionsTable/CompetitionsEditForm/validation'
import { useCustomTranslation } from '@features/translate/Translate'
import { useParams } from 'react-router-dom'

type CompetitionEditFormProps = {
  onClose: () => void
  categoryData?: IVotingCategories
}

const CompetitionEditForm: FC<CompetitionEditFormProps> = (props) => {
  const { tr } = useCustomTranslation()
  const [updateVotingCategory, { isLoading }] = useUpdateVotingCategoryMutation()
  const [addVotingCategory] = useAddVotingCategoryMutation()
  const { concourseId = '' } = useParams()
  const toast = useToast()

  console.log(props.categoryData?.concourse_id)

  const onSubmitHandler = (formFields: any) => {
    if (props.categoryData)
      return updateVotingCategory({
        ...formFields,
        id: props.categoryData?.id,
      })
        .unwrap()
        .then(() => {
          props.onClose()
          toast({
            description: tr('Информация о номинации обновлена'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((e) => {
          toast({
            description: tr('Не удалось обновить информацию'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    else
      return addVotingCategory({ ...formFields, concourse_id: +concourseId })
        .unwrap()
        .then(() => {
          props.onClose()
          toast({
            description: tr('Номинация создана'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((e) => {
          methods.setFormError()
          toast({
            description: tr('При создании номинации произошла ошибка'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
  }

  const methods = useCustomForm({
    schema: schema,
  })

  const onCloseHandler = () => {
    methods.resetAllFields()
    props.onClose()
  }

  return (
    <>
      <Heading size={'md'} mb={'5'}>
        {props.categoryData ? tr('Редактировать номинацию') : tr('Создать номинацию')}
      </Heading>
      <Form onSubmit={onSubmitHandler} {...methods}>
        <TextInput
          inputName={'name'}
          labelText={tr('Название номинации') + ':'}
          defaultValue={props.categoryData?.name || ''}
        />
        <Stack direction={['column', 'row']} mt={8}>
          <Button type={'submit'} isLoading={isLoading}>
            {tr('Сохранить')}
          </Button>
          <Button type="reset" onClick={onCloseHandler}>
            {tr('Отменить')}
          </Button>
        </Stack>
      </Form>
    </>
  )
}

export default CompetitionEditForm
