import {
  useGetEventUserPermissionsQuery,
  useGetUserGlobalPermissionsQuery,
} from './rolesApiSlice'
import { IPermission, IServerPermission } from './types'
import { selectCurrentEventId } from '@features/events'
import { useSelector } from 'react-redux'
import { useMemoizedNestedObject } from '@hooks/useMemoizedNestedObject'

export const usePermissions = (): IPermission[] | undefined => {
  const currentEventId = useSelector(selectCurrentEventId)
  const {
    data: globalPermissionsRaw,
    isLoading: isLoadingGP,
    error: errorGP,
  } = useGetUserGlobalPermissionsQuery()
  const {
    data: eventPermissions,
    isLoading: isLoadingEP,
    error: errorEP,
  } = useGetEventUserPermissionsQuery(currentEventId!, {
    skip: !currentEventId,
  })
  // if (errorGP || errorEP) return null

  const globalPermissions = globalPermissionsRaw?.permissions

  const deleteUnusedPermissions = (
    eventPermissions?: IServerPermission[],
    permissionName = 'events'
  ): IServerPermission[] | undefined =>
    //FIXME: удаляет пермишн events для внутри эвентных пермишенов
    eventPermissions &&
    eventPermissions.filter((permission) => permission.name !== permissionName)

  const permissionAdapter = (serverPermissions: IServerPermission[]): IPermission[] => {
    return serverPermissions?.map((permission) => {
      return { action: 'edit', subject: permission.name }
    })
  }

  // function mergeArrays(permissionArray1?: any[], permissionArray2?: any[]): any[] {
  //   console.log(
  //     permissionArray1,
  //     permissionArray2,
  //     Object.assign([], permissionArray1, permissionArray2)
  //   )
  //   return Object.assign(
  //     [],
  //     permissionArray1 && permissionArray1.sort((a, b) => a.id - b.id),
  //     permissionArray2 && permissionArray2.sort((a, b) => a.id - b.id)
  //   )
  // }

  function mergeArrays<T>(array1: T[] | undefined, array2: T[] | undefined): T[] {
    let set = new Set((array1 || []).map((obj) => JSON.stringify(obj)))

    let result = (array2 || []).filter((obj) => {
      let key = JSON.stringify(obj)
      if (!set.has(key)) {
        set.add(key)
        return true
      }
      return false
    })
    // console.log(array1, array2, result.concat(array1 || []))
    return result.concat(array1 || [])
  }

  const permissionArray: IServerPermission[] = mergeArrays<IServerPermission>(
    deleteUnusedPermissions(eventPermissions),
    globalPermissions
  )
  // console.log(permissionAdapter(permissionArray))
  return useMemoizedNestedObject(permissionAdapter(permissionArray))
}
