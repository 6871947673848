import React, { FC, ReactNode } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'

export type ModalExtendedProps = ModalProps

const ModalExtended: FC<ModalExtendedProps> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={props.size ?? { base: 'full', lg: '4xl' }}
    >
      <ModalOverlay>
        <ModalContent py={'7'} px={['0', '3']}>
          <ModalCloseButton />
          {props.children}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default ModalExtended
