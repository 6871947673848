import { FC } from 'react'
import { Center, Flex, Spinner as ChackraSpinner } from '@chakra-ui/react'

const Spinner: FC = () => {
  //чтобы спиннер всегда был по центру, в родителе используй <Flex minH={'NNNpx'} flexDirection={'column'}>
  return (
    <Center flex={'1'}>
      <ChackraSpinner />
    </Center>
  )
}

export default Spinner
