import React from 'react'
import {
  AlertDialog as CHAlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useCustomTranslation } from '@features/translate/Translate'

export type AlertDialogProps = {
  onClose: () => void
  open: boolean
  onSubmit: (e: React.MouseEvent) => void
  title: string
  text: string
}

export function AlertDialog(props: AlertDialogProps) {
  const { tr } = useCustomTranslation()
  const cancelRef = React.useRef(null)
  return (
    <CHAlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
      isOpen={props.open}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{props.title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{props.text}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={props.onClose}>
            {tr('buttons.no')}
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={(e) => {
              props.onClose()
              props.onSubmit(e)
            }}
          >
            {tr('buttons.yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </CHAlertDialog>
  )
}
