//TODO: сделать перевод для yup нативной функцией
import React, { ElementType, FC } from 'react'
import { useSteps, UseStepsReturn } from './hooks/useSteps'
import { useFormError, useFormErrorReturn } from './hooks/useFormError'
import { MultistepFormProvider } from './MultistepFormProvider'
import { AnyObjectSchema } from 'yup'

type MultistepFormProps = {
  schema: (getErrorText: useFormErrorReturn['getErrorText']) => {
    [key: string]: AnyObjectSchema
  }
  stepComponents: ElementType[]
}

export type MultistepFormComponentProps = {
  schema: AnyObjectSchema
  stepMethods: UseStepsReturn
}

const MultistepForm: FC<MultistepFormProps> = ({ schema, stepComponents }) => {
  const stepMethods = useSteps(stepComponents)
  const { CurrentStepComponent, currentStepIndex } = stepMethods
  const { getErrorText } = useFormError() //передаем в схему функцию перевода, по-другому никак
  return (
    <MultistepFormProvider>
      <CurrentStepComponent
        schema={schema(getErrorText)[currentStepIndex]}
        stepMethods={stepMethods}
      />
    </MultistepFormProvider>
  )
}

export default MultistepForm
