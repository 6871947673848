import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { useLocation } from 'react-router-dom'
import useFormPersist from 'react-hook-form-persist'
import { AnyObjectSchema } from 'yup'
import { useEffect } from 'react'

interface IUseCustomForm extends UseFormProps {
  schema?: AnyObjectSchema //схема валидации yup
  firstFieldFocus?: boolean //автофокус на первом поле формы
  resetOnSubmit?: boolean //очистка всех полей после submit
  needFinalScreen?: boolean //автоматически сгенерированный финальный экран об успехе отправки
}

interface IUseCustomPersistedForm extends IUseCustomForm {
  formName: string //ключ, по которому будут сохраняться данные формы в sessionStorage
  needPersist: true //сохранение состояния формы в sessionStorage
}
interface IUseCustomNoPersistedForm extends IUseCustomForm {
  formName?: string
  needPersist?: false
}

type UseCustomFormProps = IUseCustomNoPersistedForm | IUseCustomPersistedForm

export interface IUsePersistedFormReturn extends UseFormReturn {
  resetAllFields: () => void
  deleteFormStorage: () => void
  setFormError: () => void
  clearFormError: () => void
  hasFetchError: boolean
  needFinalScreen: boolean
}

export const useCustomForm = (props?: UseCustomFormProps): IUsePersistedFormReturn => {
  const location = useLocation()
  const {
    schema,
    formName = `persist-form:${location.pathname.replace(/^\/|\/$/g, '')}`,
    needPersist = false,
    firstFieldFocus = false,
    resetOnSubmit = true,
    needFinalScreen = false,
    ...rest
  } = props ?? {}
  const methods = useForm({
    resolver: schema && yupResolver(schema),
    ...rest,
  })
  const {
    watch,
    setValue,
    reset,
    setFocus,
    formState: { isSubmitSuccessful, ...rest2 },
  } = methods
  useFormPersist(formName, {
    watch: needPersist ? watch : () => {},
    setValue,
  })

  useEffect(() => {
    if (isSubmitSuccessful && resetOnSubmit) {
      //очистка формы из памяти после успешного submit и перехода на финальный экран
      if (needFinalScreen) deleteFormStorage()
      //удаление формы из памяти и её полей после submit
      else resetAllFields()
    }
  }, [isSubmitSuccessful])

  useEffect(() => {
    //автофокус на первом поле формы
    if (firstFieldFocus) {
      const fieldNamesArray = Object.keys(methods.control._fields)
      setFocus(fieldNamesArray[0])
    }
  }, [setFocus])

  const deleteFormStorage = () => {
    //удаляет данные формы из sessionStorage
    //FIXME: задержка в 800мс при удалении из-за лишнего ререндера компонентов
    setTimeout(() => sessionStorage.removeItem(formName), 800)
  }

  const resetAllFields = () => {
    //очищает все поля формы
    reset((formValues) => {
      for (let fieldName in formValues) {
        formValues[fieldName] = null
      }
      return {
        ...formValues,
      }
    })
    deleteFormStorage()
  }

  const setFormError = () => {
    methods.setError('formError', {})
  }
  const clearFormError = () => {
    methods.clearErrors('formError')
  }
  const hasFetchError = !!methods.formState.errors.fetchError

  return {
    ...methods,
    resetAllFields,
    deleteFormStorage,
    setFormError,
    clearFormError,
    hasFetchError,
    needFinalScreen,
  }
}
