import { InputProps, PinInput, PinInputField } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputWrapper from './InputWrapper'

type CodeInputProps = InputProps & {
  inputName: string
  isRequired?: boolean
  labelText?: string
}

const PinCodeInput: FC<CodeInputProps> = (props) => {
  const { inputName, placeholder, labelText, ...rest } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <InputWrapper {...props}>
      <Controller
        control={control}
        name={inputName}
        render={({ field: { ref, ...restField } }) => (
          <PinInput
            type={'number'}
            autoFocus
            isInvalid={!!errors[inputName]}
            otp
            {...restField}
          >
            <PinInputField ref={ref} mr={['1', '2']} />
            <PinInputField ref={ref} mr={['1', '2']} />
            <PinInputField ref={ref} mr={['2', '4']} />
            <PinInputField ref={ref} mr={['1', '2']} />
            <PinInputField ref={ref} mr={['1', '2']} />
            <PinInputField ref={ref} />
          </PinInput>
        )}
      />
    </InputWrapper>
  )
}

export default PinCodeInput
