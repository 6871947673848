import { Box, Card, CardBody, Center } from '@chakra-ui/react'
import React, { FC, memo } from 'react'
import EventLogo from '../EventLogo'
import { IEvent } from '../types'

type EventCardSimpleProps = {
  isActive: boolean
  onClick: () => void
  eventData: IEvent
}

const EventCardSimple: FC<EventCardSimpleProps> = (props) => {
  return (
    <Box boxShadow={props.isActive ? 'outline' : 'none'} rounded="sm">
      <Card
        size={'sm'}
        backgroundColor={'white'}
        p={'3'}
        cursor={'pointer'}
        onClick={() => props.onClick()}
      >
        <CardBody>
          <Center>
            <EventLogo src={props.eventData.logo} alt={props.eventData.name} h="80px" />
          </Center>
        </CardBody>
      </Card>
    </Box>
  )
}

export default memo(EventCardSimple)
