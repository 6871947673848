import { Button, Checkbox, Link, Stack, useToast } from '@chakra-ui/react'
import {
  Form,
  MultistepFormComponentProps,
  TextInput,
  useCustomForm,
  useMultistepFormContext,
} from '@components/Form'
import { InferType } from 'yup'
import { useVoteMutation } from '@features/vote/voteApiSlice'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'

export default function EmailConfirm(props: MultistepFormComponentProps) {
  // throw new Error('Error in form 1')
  const [vote, { isLoading }] = useVoteMutation()
  const [searchParams] = useSearchParams()
  const [isPersonalDataChecked, setIsPersonalDataChecked] = useState(false)
  const [isEmailConfirmChecked, setIsEmailConfirmChecked] = useState(false)
  const canISendForm = isPersonalDataChecked && isEmailConfirmChecked
  const concourse_id = parseInt(searchParams.get('concourse_id') || '0')
  const category_id = parseInt(searchParams.get('category_id') || '0')
  const candidate_id = parseInt(searchParams.get('candidate_id') || '0')

  const methods = useCustomForm({
    schema: props.schema,
    firstFieldFocus: true,
  })
  const { saveFieldValues } = useMultistepFormContext()
  const toast = useToast()
  const onSubmit = (formFields: InferType<typeof props.schema>) => {
    saveFieldValues(formFields)
    return vote({
      email: formFields.email,
      concourse_id: concourse_id,
      category_id: category_id,
      candidate_id: candidate_id,
    })
      .unwrap()
      .then(() => {
        props.stepMethods.next()
      })
      .catch((e) => {
        if (e.status == 404)
          methods.setError('email', {
            type: 'custom',
            message: 'Такого пользователя нет',
          })
        else if (e.status == 422)
          methods.setError('email', {
            type: 'custom',
            message: 'Неправильный формат email',
          })
        else if (e.status == 400) {
          methods.setFormError()
          toast({
            description: 'Вы уже проголосовали ранее',
            status: 'info',
            duration: 3000,
            isClosable: true,
          })
        } else if (e.status == 429) {
          methods.setFormError()
          toast({
            description: 'Превышено количество запросов',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        } else {
          methods.setFormError()
          toast({
            description: 'При отправке данных произошла ошибка',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      })
  }

  return (
    <Form onSubmit={onSubmit} {...methods}>
      <Stack direction={['column']} spacing="4">
        <TextInput
          isRequired
          inputName={'email'}
          labelText={'Введите email для подтверждения голоса'}
          placeholder={'example@email.com'}
        />
        <Checkbox
          isRequired={true}
          size={'sm'}
          onChange={() => setIsPersonalDataChecked((prev) => !prev)}
        >
          Настоящим даю ООО «АйТиИ Экспо Интернешнл» согласие на обработку{' '}
          <Link
            target={'_blank'}
            color="blue.500"
            href="https://www.ite.group/ru/personal-data/"
          >
            персональных данных
          </Link>{' '}
          и соглашаюсь с{' '}
          <Link
            target={'_blank'}
            color="blue.500"
            href="https://www.ite.group/ru/privacy/"
          >
            политикой конфиденциальности
          </Link>
          .
        </Checkbox>
        <Checkbox
          isRequired={true}
          size={'sm'}
          onChange={() => setIsEmailConfirmChecked((prev) => !prev)}
        >
          Даю согласие на получение кода для подтверждения моего голоса на указанный адрес
          электронной почты
        </Checkbox>
      </Stack>
      <Stack direction={['column', 'row']} mt={8}>
        <Button type={'submit'} isLoading={isLoading} isDisabled={!canISendForm}>
          Далее
        </Button>
      </Stack>
    </Form>
  )
}
