import React, { FC, memo } from 'react'
import {
  Badge,
  Box,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  IconButton,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ModalSimple from '@components/ModalSimple'
import { IEvent } from '../types'
import EventLogo from '../EventLogo'
import EventEditForm from '../EventEditForm'
import { useRemoveEventMutation } from '../eventsApiSlice'
import useConfirmDialog from '@hooks/useConfirmDialog'

type EventCardExtendedProps = {
  isActive: boolean
  eventData: IEvent
}

const IdBadge = (props: { eventId?: number }) => {
  const { onCopy } = useClipboard(`eventId=${props.eventId}`)
  const toast = useToast()
  const onClick = () => {
    onCopy()
    toast({
      description: `eventId=${props.eventId} скопирован`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }
  return (
    <Badge
      variant="outline"
      colorScheme="blue"
      fontSize="0.9em"
      _hover={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      ID: {props.eventId}
    </Badge>
  )
}

const EventCardExtended: FC<EventCardExtendedProps> = (props) => {
  const formatDate = (dateStr: string) => {
    const parts = dateStr.split('-')
    return parts[2] + '.' + parts[1] + '.' + parts[0]
  }
  const [removeEvent, { isLoading: eventIsDeleting, isError: isDeleteError }] =
    useRemoveEventMutation()

  const {
    isOpen: isOpenEEM,
    onOpen: onOpenEEM,
    onClose: onCloseEEM,
  } = useDisclosure({ defaultIsOpen: false })

  const {
    openConfirmDialog: openConfirmDeleteDialog,
    ConfirmDialog: ConfirmDeleteDialog,
  } = useConfirmDialog()
  const toast = useToast()
  const handleRemoveEvent = () => {
    removeEvent(props.eventData.id)
      .unwrap()
      .then(() => {
        toast({
          description: 'Эвент удален',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          description: 'При удалении эвента произошла ошибка',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <>
      <Card size={'sm'} backgroundColor={'white'} p={'3'}>
        <Box pos={'relative'} ml={'auto'}>
          <IdBadge eventId={props.eventData.id} />
        </Box>
        <CardHeader mt={'2'}>
          <Center>
            <EventLogo src={props.eventData.logo} alt={props.eventData.name} h={'60px'} />
          </Center>
        </CardHeader>
        <CardBody>
          <Flex justifyContent={'space-between'} flexDirection={'column'} h={'100%'}>
            <Heading size={{ base: 'sm', xl: 'md' }} pb={'3'}>
              <Text>{props.eventData.name}</Text>
            </Heading>
            <Box fontSize={{ base: 'sm', xl: 'lg' }}>
              <Text>{formatDate(props.eventData.start)}</Text>
              <Text>{formatDate(props.eventData.finish)}</Text>
            </Box>
          </Flex>
        </CardBody>

        <CardFooter>
          <ButtonGroup spacing="3">
            <IconButton
              isLoading={eventIsDeleting}
              onClick={openConfirmDeleteDialog(() => handleRemoveEvent())}
              icon={<DeleteIcon />}
              aria-label={'delete event'}
            />
            <ConfirmDeleteDialog
              title={'Удаление данных'}
              text={'Вы действительно хотите удалить эту выставку?'}
            />
            <IconButton
              icon={<EditIcon />}
              aria-label={'edit event'}
              onClick={onOpenEEM}
            />
            <ModalSimple isOpen={isOpenEEM} onClose={onCloseEEM}>
              <EventEditForm onClose={onCloseEEM} event={props.eventData} />
            </ModalSimple>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </>
  )
}

export default memo(EventCardExtended)
