import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@app/store'
import { IEvent } from './types'

interface IInitialState {
  currentEventId: IEvent['id'] | null
}

const initialState: IInitialState = { currentEventId: null }

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setCurrentEventId: (state, action: PayloadAction<IEvent['id'] | null>) => {
      state.currentEventId = action.payload
    },
    reset: () => initialState,
  },
})

export const eventsActions = eventsSlice.actions
export const eventsReducer = eventsSlice.reducer

export const selectCurrentEventId = (state: RootState) => state.events.currentEventId
