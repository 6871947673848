import React from 'react'
import { Box, Flex, Avatar, Text, VStack } from '@chakra-ui/react'
import { useAppSelector } from '@app/store'
import ProfileLayout from '@features/profile/ProfilePage/ProfileLayout'
import { useSelector } from 'react-redux'
import { selectUserProfile } from '@features/profile'
import { useCustomTranslation } from '@features/translate/Translate'

const Profile = () => {
  const { tr } = useCustomTranslation()

  const userEmail = useAppSelector((state) => state.auth.email)
  const userAvatar = ''
  const userInfo = useSelector(selectUserProfile)

  if (!userInfo || !userEmail) return <div>Error loading profile or user not found</div>

  return (
    <ProfileLayout>
      <Flex w={'100%'}>
        <Box
          borderWidth="1px"
          borderRadius="md"
          p={4}
          boxShadow="lg"
          bg="white"
          height="100%"
          width="100%"
        >
          <Flex flexDirection="row">
            <Box flex="1" pl={4} padding={4}>
              <VStack spacing={4} align="center">
                <Avatar size="xl" bg="blue.500" name={userEmail} src={userAvatar} />
                <Text fontSize="25px" fontWeight="350" color="blackAlpha.900">
                  {tr('Добро пожаловать')}, {userInfo.firstName} {userInfo.lastName}
                </Text>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </ProfileLayout>
  )
}

export default Profile
