import React, { FC, memo } from 'react'
import {
  ButtonGroup,
  IconButton,
  Td,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { IVotingCandidates } from '@features/vote/types'
import useConfirmDialog from '@hooks/useConfirmDialog'
import ModalSimple from '@components/ModalSimple'
import NominationEditForm from '@features/vote/NominationTable/NominationEditForm'
import { useDeleteVotingCandidateMutation } from '@features/vote/voteApiSlice'
import { GrCode } from 'react-icons/gr'
import CandidateSourceModal from '@features/vote/NominationTable/CandidateSourceModal'
import { useCustomTranslation } from '@features/translate/Translate'

type CandidateRequestProps = {
  candidateData: IVotingCandidates
}

const NominationTableRequest: FC<CandidateRequestProps> = ({ candidateData }) => {
  const { tr } = useCustomTranslation()
  const [deleteVotingCandidate, { isLoading }] = useDeleteVotingCandidateMutation()
  const { isOpen: isOpenCAEM, onOpen: onOpenCAEM, onClose: onCloseCAEM } = useDisclosure()
  const { isOpen: isOpenCode, onOpen: onOpenCode, onClose: onCloseCode } = useDisclosure()
  const {
    openConfirmDialog: openConfirmDeleteDialog,
    ConfirmDialog: ConfirmDeleteDialog,
  } = useConfirmDialog()
  const toast = useToast()
  const handleRemoveCandidate = () => {
    deleteVotingCandidate({ id: candidateData.id })
      .unwrap()
      .then(() => {
        toast({
          description: tr('Номинант удалён'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          description: tr('При удалении номинанта произошла ошибка'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }
  // console.log(candidateData)
  return (
    <Tr>
      <Td>{candidateData.name}</Td>
      <Td>{candidateData.total_votes}</Td>
      <Td textAlign={'right'}>
        <ButtonGroup size={'sm'}>
          <IconButton aria-label="Edit" icon={<GrCode />} onClick={onOpenCode} />
          <IconButton aria-label="Edit" icon={<EditIcon />} onClick={onOpenCAEM} />
          <IconButton
            aria-label="Delete"
            icon={<DeleteIcon />}
            onClick={openConfirmDeleteDialog(() => handleRemoveCandidate())}
          />
          <ConfirmDeleteDialog
            title={tr('Удаление данных')}
            text={tr('Вы действительно хотите удалить этого номинанта?')}
          />
        </ButtonGroup>
        <ModalSimple size={'lg'} isOpen={isOpenCAEM} onClose={onCloseCAEM}>
          <NominationEditForm candidateData={candidateData} onClose={onCloseCAEM} />
        </ModalSimple>
        <ModalSimple size={'lg'} isOpen={isOpenCode} onClose={onCloseCode}>
          <CandidateSourceModal candidateData={candidateData} onClose={onCloseCode} />
        </ModalSimple>
      </Td>
    </Tr>
  )
}

export default memo(NominationTableRequest)
