import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import React, { FC, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

export type InputWrapperProps = FormControlProps & {
  inputName: string
  labelText?: string
  children?: ReactNode
}

const InputWrapper: FC<InputWrapperProps> = ({
  inputName,
  isRequired,
  labelText,
  children,
  ...rest
}) => {
  const {
    formState: { errors },
  } = useFormContext()
  return (
    <FormControl isInvalid={!!errors[inputName]} isRequired={isRequired} {...rest}>
      {labelText && (
        <FormLabel htmlFor={inputName} mb={'2'}>
          {labelText}
        </FormLabel>
      )}
      {children}
      <ErrorMessage
        errors={errors}
        name={inputName}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}

export default InputWrapper
