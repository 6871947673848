import { ElementType, useState } from 'react'

export type UseStepsReturn = {
  currentStepIndex: number
  CurrentStepComponent: ElementType
  steps: ElementType[]
  isFirstStep: boolean
  isLastStep: boolean
  goTo: (index: number) => void
  next: () => void
  back: () => void
}

export function useSteps(steps: ElementType[]): UseStepsReturn {
  const [currentStepIndex, setCurrentStepIndex] = useState(1)

  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps.length) return i
      return i + 1
    })
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 1) return i
      return i - 1
    })
  }

  function goTo(index: number) {
    setCurrentStepIndex(index)
  }

  return {
    currentStepIndex,
    CurrentStepComponent: steps[currentStepIndex - 1],
    steps,
    isFirstStep: currentStepIndex === 1,
    isLastStep: currentStepIndex === steps.length,
    goTo,
    next,
    back,
  }
}
