import React, { FC, memo, ReactNode, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useCustomTranslation } from '@features/translate/Translate'
import {
  Flex,
  Heading,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { PlusSquareIcon } from '@chakra-ui/icons'
import { useGetVotingCandidatesQuery } from '@features/vote/voteApiSlice'
import NominationTableRequest from '@features/vote/NominationTable/NominationTableRequest'
import ModalSimple from '@components/ModalSimple'
import NominationEditForm from '@features/vote/NominationTable/NominationEditForm'

const ThText: FC<{ children: ReactNode }> = ({ children }) => (
  <Text mb={'3'} fontSize={'xs'}>
    {children}
  </Text>
)
const NominationTable: FC = () => {
  const { concourseId = '' } = useParams()
  const { nominationId = '' } = useParams()
  const { tr } = useCustomTranslation()
  const { data = [] } = useGetVotingCandidatesQuery({
    concourseId,
    nominationId,
  })
  const location = useLocation()
  const categoryTitle = location.state
  const { isOpen: isOpenCAEM, onOpen: onOpenCAEM, onClose: onCloseCAEM } = useDisclosure()
  const sortedByNominantId = useMemo(
    () => (data ? [...data].sort((a, b) => a.id - b.id) : []),
    [data]
  )
  // console.log(location)
  return (
    <>
      <Flex
        backgroundColor={'white'}
        borderRadius={'md'}
        p={'5'}
        minH={'100px'}
        flexDirection={'column'}
      >
        <Heading as="h1" size="md" mb={'5'}>
          {categoryTitle}
        </Heading>
        <Flex justifyContent="right" mb="2">
          <Tooltip hasArrow label={tr('Добавить номинанта')} bg="gray.300" color="black">
            <IconButton
              icon={<PlusSquareIcon />}
              aria-label="Add a nominee"
              variant="outline"
              onClick={onOpenCAEM}
            />
          </Tooltip>
          <ModalSimple size={'lg'} isOpen={isOpenCAEM} onClose={onCloseCAEM}>
            <NominationEditForm onClose={onCloseCAEM} />
          </ModalSimple>
        </Flex>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th width={'50%'}>
                  <ThText>{tr('Номинанты')}</ThText>
                </Th>
                <Th width={'50%'}>
                  <ThText>{tr('Кол-во голосов')}</ThText>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedByNominantId.map((candidateRequest) => {
                return (
                  <NominationTableRequest
                    key={candidateRequest.id}
                    candidateData={candidateRequest}
                  />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </>
  )
}

export default memo(NominationTable)
