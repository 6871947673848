import React, { useState } from 'react'
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  InputGroup,
  ButtonGroup,
} from '@chakra-ui/react'
import ProfileLayout from '@features/profile/ProfilePage/ProfileLayout'
import { useActions, useAppSelector } from '@app/store'
import {
  useChangeEmailMutation,
  useConfirmChangeEmailMutation,
} from '@features/profile/profileApiSlice'
import { UpdateUserSuccessModal } from './Modals/UpdateUserSuccessModal'
import { useCustomTranslation } from '@features/translate/Translate'

const Security: React.FC = () => {
  const { tr } = useCustomTranslation()

  const userEmail = useAppSelector((state) => state.auth.email)

  const [emailChange, setEmailChange] = useState({
    newEmail: '',
  })

  const [codeChange, setCodeChange] = useState({
    currentEmailCode: '',
    newEmailCode: '',
  })
  const [isEmailChangeInitiated, setEmailChangeInitiated] = useState(false)

  const [changeEmail, { isSuccess }] = useChangeEmailMutation()
  const [confirmChangeEmail] = useConfirmChangeEmailMutation()

  const [isModalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const { setEmail } = useActions()

  const confirmEmailChange = async () => {
    const error = validateEmail(emailChange.newEmail)
    if (error) {
      setModalMessage(error)
      setModalOpen(true)
      return
    }
    try {
      await confirmChangeEmail({
        data: {
          currentEmailCode: codeChange.currentEmailCode,
          newEmailCode: codeChange.newEmailCode,
          newEmail: emailChange.newEmail,
        },
      }).unwrap()
      setEmail(emailChange.newEmail)
      setEmailChangeInitiated(false)

      setModalMessage(tr('Email успешно изменен.'))
      setModalOpen(true)
      setEmailChange({ newEmail: '' })
    } catch (error) {}
  }

  const validateEmail = (newEmail: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(newEmail)) {
      return tr('Введите действительный адрес электронной почты.')
    }

    if (newEmail === userEmail) {
      return tr('Новый адрес электронной почты должен отличаться от текущего.')
    }

    return ''
  }

  if (!userEmail) return <div>Error loading profile or user not found</div>

  const handleEmailChange = async () => {
    const error = validateEmail(emailChange.newEmail)
    if (error) {
      setModalMessage(error)
      setModalOpen(true)
      return
    }
    try {
      await changeEmail({
        data: { newEmail: emailChange.newEmail, email: userEmail },
      }).unwrap()
      setEmailChangeInitiated(true)
    } catch (apiError) {
      const errorResponse = apiError as { originalStatus?: number; data?: any }

      if (errorResponse.originalStatus === 400) {
        setModalMessage(tr('Данный адрес электронной почты уже используется.'))
        setEmailChangeInitiated(false)
      } else {
        setModalMessage(tr('Произошла ошибка при изменении Email.'))
        setEmailChangeInitiated(false)
      }
      setModalOpen(true)
      return
    }
    setEmailChangeInitiated(true)
  }

  return (
    <ProfileLayout>
      <Heading as="h2" size="md" py={4}>
        {tr('Безопасность')}
      </Heading>
      <Box borderWidth="1px" borderRadius="md" p={4} boxShadow="lg" bg="white">
        <VStack spacing={6} align="start">
          <FormControl>
            <FormLabel htmlFor="email">Ваш Email</FormLabel>
            <InputGroup size="md">
              <Input value={userEmail} disabled={true} />
            </InputGroup>
            <FormLabel>{tr('Новый Email')}</FormLabel>
            <Input
              type="email"
              value={emailChange.newEmail}
              placeholder={tr('Введите новый email')}
              onChange={(e) => setEmailChange({ newEmail: e.target.value })}
            />
          </FormControl>
          {!isEmailChangeInitiated && (
            <Box mt={4}>
              <Button
                isDisabled={emailChange.newEmail.length < 2}
                colorScheme="blue"
                mr={3}
                onClick={handleEmailChange}
              >
                {tr('Изменить email')}
              </Button>
            </Box>
          )}
          {isEmailChangeInitiated && (
            <VStack spacing={4}>
              <Input
                placeholder={tr('Код с текущего Email')}
                value={codeChange.currentEmailCode}
                onChange={(e) =>
                  setCodeChange({ ...codeChange, currentEmailCode: e.target.value })
                }
              />
              <Input
                placeholder={tr('Код с нового Email')}
                value={codeChange.newEmailCode}
                onChange={(e) =>
                  setCodeChange({ ...codeChange, newEmailCode: e.target.value })
                }
              />
              <Box mt={4}>
                <ButtonGroup>
                  <Button
                    colorScheme="blue"
                    isDisabled={
                      codeChange.currentEmailCode.length !== 6 ||
                      codeChange.newEmailCode.length !== 6
                    }
                    onClick={confirmEmailChange}
                  >
                    {tr('Подтвердить изменение Email')}
                  </Button>
                  <Button px={4} colorScheme="gray" onClick={handleEmailChange}>
                    {tr('Отправить повторно')}
                  </Button>
                </ButtonGroup>
              </Box>
            </VStack>
          )}
        </VStack>
      </Box>
      <UpdateUserSuccessModal
        isOpen={isModalOpen}
        message={modalMessage}
        onClose={() => setModalOpen(false)}
      />
    </ProfileLayout>
  )
}

export default Security
