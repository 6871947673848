import { FormProvider, SubmitHandler } from 'react-hook-form'
import React, { FC, memo, ReactNode, useEffect } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { IUsePersistedFormReturn } from './hooks/useCustomForm'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

type FormProps = IUsePersistedFormReturn & {
  children: ReactNode
  onSubmit: SubmitHandler<any>
}

const Form: FC<FormProps> = (props) => {
  const { t } = useTranslation()
  const { onSubmit, children, clearFormError, ...rest } = props
  // const toast = useToast()
  // useEffect(() => {
  //   //FIXME: отслеживать другой статус при отправке на сервер, этот некорректный
  //   if (!props.needFinalScreen && props.formState.isSubmitSuccessful) {
  //     toast({
  //       description: 'Данные успешно отправлены',
  //       status: 'success',
  //       duration: 3000,
  //       isClosable: true,
  //     })
  //   }
  // }, [props.formState.isSubmitSuccessful])

  useEffect(() => {
    if (props.hasFetchError) {
      //проверяет, была ли задана кастомная ошибка через setFormError()
      // toast({
      //   description: 'Во время отправки данных возникла ошибка',
      //   status: 'error',
      //   duration: 3000,
      //   isClosable: true,
      // })
      //очищает ошибку, чтобы можно было использовать onSubmit() заново
      props.clearFormError()
    }
  }, [props.formState])

  if (props.needFinalScreen && props.formState.isSubmitSuccessful)
    return (
      <Flex align={'center'} gap={'3'}>
        <CheckCircleIcon color="teal" boxSize={'1.5em'} />
        <Text fontSize={'xl'}>{t('Данные формы успешно отправлены')}</Text>
      </Flex>
    )

  return (
    <FormProvider {...rest}>
      <form onSubmit={props.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  )
}

export default memo(Form)
