import React, { FC } from 'react'
import { ModalBody } from '@chakra-ui/react'
import ModalExtended, { ModalExtendedProps } from '@components/ModalExtended'
import ErrorBoundary from '@components/ErrorBoundary'

type ModalSimpleProps = ModalExtendedProps

const ModalSimple: FC<ModalSimpleProps> = (props) => {
  return (
    <ModalExtended
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={props.size ?? ['full', 'xs']}
    >
      <ModalBody pt={'7'}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </ModalBody>
    </ModalExtended>
  )
}

export default ModalSimple
