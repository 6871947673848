//TODO: сделать атрибут status, сделать перевод через
//TODO: разные коды ошибок для всего приложения и для форм
import { FC } from 'react'
import { Alert, AlertIcon } from '@chakra-ui/react'
import { Translate } from '@features/translate'
import * as Sentry from '@sentry/react'

type ErrorComponentProps = {
  error?: any
  i18key?: string
}
const ErrorFallback: FC<ErrorComponentProps> = (props) => {
  const { i18key, error } = props
  // Sentry.captureException(error.message)
  return (
    <>
      <Alert status="error">
        <AlertIcon />
        {i18key ? (
          <Translate i18key={i18key} />
        ) : (
          'Ошибка: ' + Sentry.captureException(error.message)
        )}
      </Alert>
    </>
  )
}

export default ErrorFallback
