import React, { FC, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  IconButton,
  ModalBody,
  ModalHeader,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import { EditIcon, PlusSquareIcon } from '@chakra-ui/icons'
import ModalSimple from '@components/ModalSimple'
import EventsControl from '../EventsControl/EventsControl'
import EventEditForm from '../EventEditForm'
import ModalExtended from '@components/ModalExtended'
import { Can } from '@features/roles'
import { useGetEventsQuery } from '@features/events'

type EventsControlPanelProps = { isOpen: boolean; onClose: () => void;}

const EventsControlPanel: FC<EventsControlPanelProps> = (props) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const { isOpen: isOpenEEM, onOpen: onOpenEEM, onClose: onCloseEEM } = useDisclosure()
  const { data } = useGetEventsQuery()
  // const years = [
  //   ...new Set(
  //     data?.map((event) => new Date(event.start).getFullYear()).sort((a, b) => b - a)
  //   ),
  // ]
  const y = [...new Set(data?.map((event) => event.start.slice(0,4)).sort().reverse())]
  const [selectedYear, setSelectedYear] = useState('')
  
  const selectYearHandler = (e: any) => {
    localStorage.setItem('year', e.target.value)
    setSelectedYear(e.target.value)
  }

  useEffect(() => {
    const storedYear = localStorage.getItem('year')
    if (storedYear) {
      setSelectedYear(storedYear)
    }
  },[])


  return (
    <>
      <ModalSimple isOpen={isOpenEEM} onClose={onCloseEEM}>
        <EventEditForm onClose={onCloseEEM} />
      </ModalSimple>
      <ModalExtended
        isOpen={props.isOpen}
        onClose={props.onClose}
        size={{ base: 'full', lg: '4xl' }}
        preserveScrollBarGap
      >
        <ModalHeader>
          <Flex gap={'3'} alignItems={'center'}>
            <Can I="edit" a="events">
              <IconButton
                onClick={() => setEditMode(!editMode)}
                icon={<EditIcon />}
                aria-label={'edit events'}
                colorScheme={editMode ? 'blue' : 'gray'}
              />
              <IconButton
                onClick={onOpenEEM}
                icon={<PlusSquareIcon />}
                aria-label={'add event'}
              />
              <Box>
                <Select
                  defaultValue={selectedYear}
                  onChange={selectYearHandler}
                  placeholder="Выберите год"
                  // variant="filled"
                >
                  {y.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </Box>
            </Can>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <EventsControl
            onClose={props.onClose}
            editMode={editMode}
            selectedYear={selectedYear}
          />
        </ModalBody>
      </ModalExtended>
    </>
  )
}

export default EventsControlPanel
