import { IVotingCandidates } from '@features/vote/types'
import { Button, Heading, Stack, useToast } from '@chakra-ui/react'
import { Form, TextInput, useCustomForm } from '@components/Form'
import React, { FC } from 'react'
import {
  useAddVotingCandidateMutation,
  useUpdateVotingCandidateMutation,
} from '@features/vote/voteApiSlice'
import { useCustomTranslation } from '@features/translate/Translate'
import { schema } from '@features/vote/CompetitionsTable/CompetitionsEditForm/validation'
import { useParams } from 'react-router-dom'

type NominationEditFormProps = {
  onClose: () => void
  candidateData?: IVotingCandidates
}

const NominationEditForm: FC<NominationEditFormProps> = (props) => {
  const { tr } = useCustomTranslation()
  const { nominationId = '' } = useParams()
  const [updateVotingCandidate, { isLoading }] = useUpdateVotingCandidateMutation()
  const [addVotingCandidate] = useAddVotingCandidateMutation()
  const toast = useToast()
  const onSubmitHandler = (formFields: any) => {
    if (props.candidateData)
      return updateVotingCandidate({
        ...formFields,
        id: props.candidateData?.id,
      })
        .unwrap()
        .then(() => {
          props.onClose()
          toast({
            description: tr('Информация о номинанте обновлена'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((e) => {
          toast({
            description: tr('Не удалось обновить информацию'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    else
      return addVotingCandidate({ ...formFields, category_id: +nominationId })
        .unwrap()
        .then(() => {
          props.onClose()
          toast({
            description: tr('Номинант создан'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((e) => {
          methods.setFormError()
          toast({
            description: tr('При создании номинанта произошла ошибка'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
  }

  const methods = useCustomForm({
    schema: schema,
  })

  const onCloseHandler = () => {
    methods.resetAllFields()
    props.onClose()
  }
  return (
    <>
      <Heading size={'md'} mb={'5'}>
        {props.candidateData ? tr('Редактировать номинанта') : tr('Создать номинанта')}
      </Heading>
      <Form onSubmit={onSubmitHandler} {...methods}>
        <TextInput
          inputName={'name'}
          labelText={tr('Название номинанта') + ':'}
          defaultValue={props.candidateData?.name || ''}
        />
        <Stack direction={['column', 'row']} mt={8}>
          <Button type={'submit'} isLoading={isLoading}>
            {tr('Сохранить')}
          </Button>
          <Button type="reset" onClick={onCloseHandler}>
            {tr('Отменить')}
          </Button>
        </Stack>
      </Form>
    </>
  )
}

export default NominationEditForm
