import React from 'react'
import {schema} from './validation'
import {MultistepForm} from '@components/Form'
import Step_1 from './steps/Step_1'
import Step_2 from './steps/Step_2'
import Step_final from './steps/Step_final'
import {Flex, Heading} from '@chakra-ui/react'
import {EventLogo, useGetEventLogoQuery} from "@features/events";
import {useSearchParams} from "react-router-dom";

const VotePublicForm = () => {
    const [searchParams] = useSearchParams()
    const eventId = searchParams.get('eventId')  as string
    const {data} = useGetEventLogoQuery(Number(eventId))
  return (
    <>
      <Flex mb={[5, 10]} gap={4} flexDirection={'column'}>
          {data && data.url&&<EventLogo
              src={data.url}
              height={'50px'}
              maxW={['100px', '100px']}
              display={['flex', 'flex']}
              _hover={{ cursor: 'pointer' }}
              alignSelf={'start'}
          />}
        {/*<Icon as={AiFillCheckCircle} w={[50, 30]} h={[50, 30]} color={'gray.1000'} />*/}
        <Heading size={'lg'}>Голосование</Heading>
      </Flex>
      <MultistepForm schema={schema} stepComponents={[Step_1, Step_2, Step_final]} />
    </>
  )
}

export default VotePublicForm
