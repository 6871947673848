//TODO:если не передали setError то отработка этой ошибки
import { t } from '@features/translate'
import { UseFormReturn } from 'react-hook-form/dist/types/form'

export type useFormErrorReturn = {
  setErrorByStatus: (fieldName: string, responceStatus?: string) => void
  getErrorText: (errorName: string) => string
}

export const useFormError = (
  setError?: UseFormReturn['setError']
): useFormErrorReturn => {
  return {
    setErrorByStatus: (fieldName, responceStatus) => {
      //устанавливает ошибку в зависимости от её кода для полей react-hook-form
      if (!setError) throw new Error('no setError function provided')

      const errorMessage = t('errors.form.' + responceStatus)
      return setError(fieldName, { type: 'custom', message: errorMessage })
    },
    getErrorText: (errorName?: string) => {
      //возвращает текст ошибки для полей формы в yup схеме
      return t('errors.form.' + errorName)
    },
  }
}
