import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@app/store'

interface ProfileState {
  firstName: string | null
  lastName: string | null
}

const initialState: ProfileState = {
  firstName: null,
  lastName: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<ProfileState>) => {
      const { firstName, lastName } = action.payload
      state.firstName = firstName
      state.lastName = lastName
    },
    resetProfile: () => initialState,
  },
})
export const profileActions = { ...profileSlice.actions }

export const { setUserInfo, resetProfile } = profileSlice.actions
export const profileReducer = profileSlice.reducer

export const selectUserProfile = (state: { profile: any }) => state.profile
