import React, { ChangeEvent, FC, useRef } from 'react'
import { InputGroup } from '@chakra-ui/react'
import { InputWrapperProps } from '../InputWrapper'
import { useFormContext } from 'react-hook-form'

type FileUploadWrapperProps = InputWrapperProps & {
  accept?: string
  multiple?: boolean
}

const FileUploadWrapper: FC<FileUploadWrapperProps> = (props) => {
  const { inputName, placeholder, multiple, accept, ...rest } = props
  const { register } = useFormContext()
  const { ref, onChange, ...regRest } = register(inputName)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e)
    props.onChange && props.onChange(e)
  }

  return (
    <InputGroup onClick={() => inputRef.current?.click()} cursor={'pointer'} {...rest}>
      <input
        id={inputName}
        type={'file'}
        multiple={!!multiple}
        accept={accept}
        hidden
        placeholder={placeholder || ''}
        ref={(e) => {
          ref(e)
          inputRef.current = e
        }}
        onChange={handleFileChange}
        {...regRest}
      />
      {props.children}
    </InputGroup>
  )
}

export default FileUploadWrapper
