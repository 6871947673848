import { apiSlice } from '@app/apiSlice'
import { IEvent } from '@features/events'
import { IServerPermission, IUser } from './types'

export const rolesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Roles'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserGlobalPermissions: builder.query<IUser, void>({
        query: () => `/account`,
      }),
      getEventUserPermissions: builder.query<IServerPermission[], IEvent['id']>({
        query: (eventId) => `/events/${eventId}/permissions`,
      }),
    }),
  })

export const {
  useGetUserGlobalPermissionsQuery,
  useLazyGetUserGlobalPermissionsQuery,
  useGetEventUserPermissionsQuery,
  useLazyGetEventUserPermissionsQuery,
} = rolesApiSlice
