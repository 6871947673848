//TODO: заменить типы any на вменяемые

import { Route, Routes } from 'react-router-dom'
import React, { Suspense } from 'react'
import { Flex } from '@chakra-ui/react'

import Spinner from '@components/Spinner'
import ErrorBoundary from '@components/ErrorBoundary'
import { useCustomAbility } from '@features/roles'
import VotePublicForm from '@features/vote/VotePublicForm'
import CompetitionTable from '@features/vote/CompetitionTable'
import VotingAdminPage from '@features/vote/VotingAdminPage'
import NominationTable from '@features/vote/NominationTable'
import PersonalInfo from '@features/profile/ProfilePage/PersonalInfo'
import Security from '@features/profile/ProfilePage/Security'

import * as Sentry from '@sentry/react' //Sentry init
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const AuthLayout = React.lazy(() => import('@layouts/AuthLayout'))
const PublicFormLayout = React.lazy(() => import('@layouts/PublicFormLayout'))
const AppLayout = React.lazy(() => import('@layouts/AppLayout'))

const Home = React.lazy(() => import('@features/Home'))

const PrintTemplates = React.lazy(() =>
  import('@features/printTemplates').then((module) => ({
    default: module.PrintTemplatesPage,
  }))
)
const Login = React.lazy(() =>
  import('@features/auth').then((module) => ({
    default: module.LoginPage,
  }))
)
const RequireAuth = React.lazy(() =>
  import('@features/auth').then((module) => ({
    default: module.RequireAuth,
  }))
)
const AccredPublicForm = React.lazy(() =>
  import('@features/accreditation').then((module) => ({
    default: module.AccredPublicForm,
  }))
)
const AccreditationTable = React.lazy(() =>
  import('@features/accreditation').then((module) => ({
    default: module.AccreditationTable,
  }))
)
const Barcodes = React.lazy(() =>
  import('@features/barcodes').then((module) => ({ default: module.BarcodesPage }))
)
const UsersTable = React.lazy(() =>
  import('@features/users').then((module) => ({
    default: module.UsersTable,
  }))
)
const CompetitionsTable = React.lazy(() =>
  import('@features/vote').then((module) => ({
    default: module.CompetitionsTable,
  }))
)

const ProfilePage = React.lazy(() =>
  import('@features/profile').then((module) => ({
    default: module.ProfilePage,
  }))
)

function App() {
  const { ability } = useCustomAbility()
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Flex minH={'100vh'}>
            <Spinner />
          </Flex>
        }
      >
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path={'/login'} element={<Login />} />
          </Route>
          <Route element={<PublicFormLayout />}>
            <Route path={'/forms/*'}>
              <Route path={'accreditation/*'} element={<AccredPublicForm />} />
            </Route>
          </Route>

          <Route element={<PublicFormLayout maxWidth={['360px', '500px']} />}>
            <Route path={'/forms/*'}>
              <Route path={'vote/*'} element={<VotePublicForm />} />
            </Route>
          </Route>

          <Route element={<RequireAuth />}>
            <Route path={'/*'} element={<AppLayout />}>
              <Route index element={<Home />} />
              {ability.can('edit', 'barcodes') && (
                <Route path={'barcodes'} element={<Barcodes />} />
              )}
              {ability.can('edit', 'printTemplates') && (
                <Route path={'printTemplates'} element={<PrintTemplates />} />
              )}
              {ability.can('edit', 'accreditation') && (
                <Route path={'accreditation'} element={<AccreditationTable />} />
              )}
              {ability.can('edit', 'users') && (
                <Route path={'users'} element={<UsersTable />} />
              )}
              {ability.can('edit', 'votings') && (
                <Route path={'votings/*'} element={<VotingAdminPage />}>
                  <Route index element={<CompetitionsTable />} />
                  <Route path=":concourseId/*">
                    <Route index element={<CompetitionTable />} />
                    <Route path=":nominationId" element={<NominationTable />} />
                  </Route>
                </Route>
              )}
              <Route path={'profile'} element={<ProfilePage />} />
              <Route path={'profile/personal-info'} element={<PersonalInfo />} />
              <Route path={'profile/security'} element={<Security />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
