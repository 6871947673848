import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { createStandaloneToast } from '@chakra-ui/react'

/**
 * Log a warning and show a toast!
 */

let lastToastTime = 0

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isRejectedWithValue(action)) {
      //TODO: добавить toast, сделать появление предупреждения только один раз
      if (action.payload.status === 'FETCH_ERROR') {
        const currentTime = new Date().getTime()
        if (currentTime - lastToastTime > 5000) {
          const { toast } = createStandaloneToast()
          console.warn(action.payload.error)
          toast({
            description: `Нет интернет-соединения`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          lastToastTime = currentTime
        }
      }
    }

    return next(action)
  }
