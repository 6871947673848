import React, { useEffect, useState } from 'react'
import {
  Box,
  VStack,
  Button,
  Heading,
  Flex,
  Input,
  FormLabel,
  ButtonGroup,
} from '@chakra-ui/react'
import ProfileLayout from '@features/profile/ProfilePage/ProfileLayout'
import { useAppSelector } from '@app/store'
import { useUserInfo } from '../hooks/useUserInfo'
import { useUpdateUserMutation } from '@features/profile/profileApiSlice'
import { UpdateUserSuccessModal } from '@features/profile/ProfilePage/Modals/UpdateUserSuccessModal'
import { useCustomTranslation } from '@features/translate/Translate'

const PersonalInfo = () => {
  const { tr } = useCustomTranslation()

  const userEmail = useAppSelector((state) => state.auth.email)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const { userInfo, refetchUserInfo, isLoading, isError } = useUserInfo(userEmail || '')
  const [updateUser, { isSuccess }] = useUpdateUserMutation()

  const [formData, setFormData] = useState({
    email: null,
    first_name: '',
    last_name: '',
  })

  useEffect(() => {
    if (userInfo && userEmail) {
      setFormData({
        email: null,
        first_name: userInfo.first_name || '',
        last_name: userInfo.last_name || '',
      })
    }
  }, [userInfo])

  if (isLoading) return <div>Loading...</div>
  if (isError || !userInfo || !userEmail)
    return <div>Error loading profile or user not found</div>

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSaveChanges = async () => {
    if (userInfo.id) {
      const response = await updateUser({ id: userInfo.id, data: formData })
      setSuccessMessage(tr('Пользовательские данные изменены'))
      setIsSuccessModalOpen(true)
      setTimeout(() => setIsSuccessModalOpen(false), 3000)
      refetchUserInfo()
    }
  }

  const handleReset = () => {
    if (userInfo) {
      setFormData({
        email: null,
        first_name: userInfo.first_name || '',
        last_name: userInfo.last_name || '',
      })
    }
  }

  const isModified =
    userInfo &&
    (formData.first_name !== userInfo.first_name ||
      formData.last_name !== userInfo.last_name)

  return (
    <ProfileLayout>
      <Heading as="h2" size="md" py={4}>
        {tr('Личная информация')}
      </Heading>
      <Box borderWidth="1px" borderRadius="md" p={4} boxShadow="lg" bg="white">
        <VStack spacing={6} align="start">
          <Flex direction="column" width="100%">
            <FormLabel>{tr('Имя')}</FormLabel>
            <Input
              name="first_name"
              placeholder={tr('Имя')}
              value={formData.first_name}
              onChange={handleInputChange}
              mb={4}
            />

            <FormLabel>{tr('Фамилия')}</FormLabel>
            <Input
              name="last_name"
              placeholder={tr('Фамилия')}
              value={formData.last_name}
              onChange={handleInputChange}
              mb={4}
            />

            <FormLabel>Email</FormLabel>
            <Input placeholder="Email" value={userEmail} mb={4} disabled={true} />
          </Flex>
          <ButtonGroup>
            <Button
              colorScheme="blue"
              size="md"
              onClick={handleSaveChanges}
              isDisabled={!isModified}
            >
              {tr('Сохранить изменения')}
            </Button>
            <Button colorScheme="gray" size="md" onClick={handleReset}>
              {tr('Сбросить изменения')}
            </Button>
          </ButtonGroup>
        </VStack>
      </Box>
      <UpdateUserSuccessModal
        isOpen={isSuccessModalOpen}
        message={successMessage}
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </ProfileLayout>
  )
}

export default PersonalInfo
