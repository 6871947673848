export const objToFormData = (data: { [key: string]: any }) => {
  const formData = new FormData()
  Object.entries(data).map(([key, value]) => {
    if (value && value?.length > 0) {
      if (typeof value === 'object') {
        formData.append(key, value[0])
      } else formData.append(key, value)
    }
  })
  return formData
}
