import React from 'react'
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import { useCustomTranslation } from '@features/translate/Translate'

interface UpdateUserSuccessModalProps {
  isOpen: boolean
  message: string
  onClose: () => void
}

export const UpdateUserSuccessModal: React.FC<UpdateUserSuccessModalProps> = ({
  isOpen,
  message,
  onClose,
}) => {
  const { tr } = useCustomTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{tr('Изменение данных')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{message}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            {tr('Закрыть')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
