import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react'

interface BreadcrumbItem {
  link: string
  name: string
}

interface BreadcrumbContextProps {
  breadcrumbs: BreadcrumbItem[]
  addBreadcrumb: (link: string, name: string) => void
  clearBreadcrumbs: () => void
  clearBreadcrumbsAfterIndex: (index: number) => void
  deleteBreadcrumbAndChildren: (link: string) => void
}

const BreadcrumbContext = createContext<BreadcrumbContextProps | undefined>(undefined)

interface BreadcrumbProviderProps {
  children: ReactNode
  localStorageKey?: string
  sessionStorageKey?: string
}

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
  localStorageKey = 'breadcrumbs',
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>(() => {
    // Retrieve breadcrumbs from localStorage on component mount
    const storedBreadcrumbs = localStorage.getItem(localStorageKey)
    if (storedBreadcrumbs) {
      return JSON.parse(storedBreadcrumbs)
    }
    return []
  })

  const updateLocalStorage = useCallback(
    (updatedBreadcrumbs: BreadcrumbItem[]) => {
      // Store updated breadcrumbs in localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(updatedBreadcrumbs))
      // console.log('bread2', localStorage.getItem(localStorageKey))
    },
    [localStorageKey]
  )

  const addBreadcrumb = useCallback(
    (link: string, name: string) => {
      // console.log('addBreadcrumb', link, name)
      // Check if the link already exists in the breadcrumbs
      if (breadcrumbs.some((breadcrumb) => breadcrumb.link === link)) {
        return // Link already exists, don't add it again
      }
      const newBreadcrumb = { link, name }
      const updatedBreadcrumbs = [...breadcrumbs, newBreadcrumb]
      // console.log('bread3')
      setBreadcrumbs(updatedBreadcrumbs)
      // console.log('brad', updatedBreadcrumbs)
      updateLocalStorage(updatedBreadcrumbs)
    },
    [breadcrumbs]
  )

  // useEffect(() => {
  //     if (breadcrumbs?.at(-1)?.link === link) {
  //         return
  //     }
  //     console.log(breadcrumbs?.at(-1)?.link, link, breadcrumbs)
  //
  //     console.log('brad2', localStorage.getItem(localStorageKey))
  // },[breadcrumbs])

  const clearBreadcrumbs = useCallback(() => {
    setBreadcrumbs([])
    localStorage.removeItem(localStorageKey)
  }, [localStorageKey])

  const clearBreadcrumbsAfterIndex = useCallback(
    (index: number) => {
      const updatedBreadcrumbs = breadcrumbs.slice(0, index + 1)
      setBreadcrumbs(updatedBreadcrumbs)
      updateLocalStorage(updatedBreadcrumbs)
    },
    [breadcrumbs, updateLocalStorage]
  )

  const deleteBreadcrumbAndChildren = useCallback(
    (linkToDelete: string) => {
      const indexToDelete = breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.link === linkToDelete
      )

      if (indexToDelete !== -1) {
        const updatedBreadcrumbs = breadcrumbs.slice(0, indexToDelete + 1)
        setBreadcrumbs(updatedBreadcrumbs)
        updateLocalStorage(updatedBreadcrumbs)
      }
    },
    [breadcrumbs, updateLocalStorage]
  )

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        addBreadcrumb,
        clearBreadcrumbs,
        clearBreadcrumbsAfterIndex,
        deleteBreadcrumbAndChildren,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
}

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext)
  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider')
  }
  return context
}
