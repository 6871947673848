import { object, string } from 'yup'

export const schema = () => ({
    1: object({
        email: string().email('Неверный формат email').required('Поле не заполнено'),
    }).required(),
    2: object({
        code: string().required('Поле не заполнено'),
    }).required(),
})
