//TODO: улучшить обработку случае если нет event
import React, { FC, useState } from 'react'
import { Button, Flex, Stack, useToast } from '@chakra-ui/react'
import { FileUploadWrapper, Form, TextInput, useCustomForm } from '@components/Form'
import { useAddEventMutation, useUpdateEventMutation } from '../eventsApiSlice'
import { IEvent } from '../types'
import EventLogo from '../EventLogo'
import { schema } from './validation'

type EventEditFormProps = {
  onClose: () => void
  event?: IEvent
}

const EventEditForm: FC<EventEditFormProps> = (props) => {
  const [updateEvent, { isLoading }] = useUpdateEventMutation()
  const [addEvent] = useAddEventMutation()

  const [logoSrc, setLogoSrc] = useState<string | undefined>(undefined)
  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    /* получаем в logoSrc base64 логотипа */
    e.preventDefault()
    if (!e.target.files) return
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      setLogoSrc(reader.result as string)
    }
    reader.readAsDataURL(file)
  }

  const onCloseHandler = () => {
    methods.resetAllFields()
    props.onClose()
  }
  const toast = useToast()
  const onSubmitHandler = (formFields: any) => {
    if (props.event)
      return updateEvent({
        ...formFields,
        id: props.event?.id,
      })
        .unwrap()
        .then(() => {
          props.onClose()
          toast({
            description: 'Информация об эвенте обновлена',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((e) => {
          methods.setFormError()
          toast({
            description: 'Не удалось обновить информацию об эвенте',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    else
      return addEvent(formFields)
        .unwrap()
        .then(() => {
          props.onClose()
          toast({
            description: 'Эвент создан',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((e) => {
          methods.setFormError()
          toast({
            description: 'При создании эвента произошла ошибка',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
  }
  const methods = useCustomForm({
    schema: schema,
    resetOnSubmit: false,
  })

  return (
    <div>
      <Form onSubmit={onSubmitHandler} {...methods}>
        <Stack direction={['column']} spacing="4">
          <FileUploadWrapper
            accept={'image/*'}
            inputName={'logo'}
            onChange={handleImageChange}
          >
            <Flex h={'100px'} w={'100%'} justifyContent={'center'} alignItems={'center'}>
              <EventLogo
                src={logoSrc || props.event?.logo || null}
                alt={props.event?.name}
                width={'90%'}
                _hover={{ opacity: '0.8' }}
              />
            </Flex>
          </FileUploadWrapper>
          <TextInput
            isRequired
            inputName={'name'}
            labelText={'Название выставки'}
            placeholder={'ITE Event'}
            defaultValue={props.event?.name || ''}
          />
          <TextInput
            isRequired
            inputName={'start'}
            labelText={'Дата начала'}
            placeholder={'YYYY-MM-DD'}
            defaultValue={props.event?.start || ''}
          />
          <TextInput
            isRequired
            inputName={'finish'}
            labelText={'Дата окончания'}
            placeholder={'YYYY-MM-DD'}
            defaultValue={props.event?.finish || ''}
          />
        </Stack>
        <Stack direction={['column', 'row']} mt={8}>
          <Button type={'submit'} isLoading={isLoading}>
            Сохранить
          </Button>
          <Button type="reset" onClick={onCloseHandler}>
            Отменить
          </Button>
        </Stack>
      </Form>
    </div>
  )
}

export default EventEditForm
